import React, { useCallback, useEffect, useRef, useState } from "react";
import ViewSalesStyle from "./ViewSalesStyle";
import api from "../../api";
import { get } from "lodash";
import { ErrorModal, SuccessModal } from "../../components/Modal";
import { useLocation } from "react-router-dom";
import { Layout, Loader } from "../../components";
import { useTranslation } from "react-i18next";

const ViewSales = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const CardCode = get(location, "state.CardCode", 0);
  const DocEntry = get(location, "state.DocEntry", 0);

  const errorRef = useRef();
  const successRef = useRef();

  const getErrorRef = useCallback((ref) => {
    errorRef.current = ref;
  }, []);
  const getSuccessRef = useCallback((ref) => {
    successRef.current = ref;
  }, []);

  const [mainData, setMainData] = useState();
  const [bonus, setBonus] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [serialNumbersData, setSerialNumbersData] = useState([]);

  useEffect(() => {
    getInformationOfItem();
  }, []);

  const getInformationOfItem = () => {
    setIsLoading(true);
    api
      .get(
        `CreditNotes(${DocEntry})?$select=DocTotal,CardName,CardCode,DocDate,DocDueDate,DocEntry,DocNum,PaidToDate,DocumentLines,SalesPersonCode,U_SeriyaNomer,Comments`
      )
      .then((res) => {
        setMainData(JSON.parse(res.data));
        let serialN = get(JSON.parse(res.data), "U_SeriyaNomer", "");
        setSerialNumbersData(serialN.split("/r"));
        setIsLoading(false);
        let documentLines = get(JSON.parse(res.data), "DocumentLines", []);
        let sumU_cashback = 0;
        for (let i = 0; i < documentLines.length; i++) {
          sumU_cashback += documentLines[i].U_Cashback;
        }
        setBonus(sumU_cashback);
      })
      .catch((err) => {
        console.log("err getInformationOfItem", err);
        setIsLoading(false);
      });
  };

  return (
    <ViewSalesStyle>
      <Layout>
        <div className='container'>
          <div className='centerCard'>
            <div className='betweenCard'>
              <p className='fio'>{t("ФИО")}</p>
              <input
                type='text'
                className='inputDefault'
                value={get(mainData, "CardName", "Клиент")}
                disabled={true}
              />

              <p className='fio'>{t("Бонус")}</p>
              <input
                type='text'
                className='inputDefault'
                value={bonus}
                disabled={true}
              />
              <p className='greenTitle'>
                {t("Оплаченный")}: {get(mainData, "PaidToDate", 0)}
                {"$"}
              </p>
            </div>

            <div className='betweenCard'>
              <div className='between'>
                <div className='betweenMini'>
                  <p className='fio'>{t("Дата")}</p>
                  <input
                    type='date'
                    className='inputDefault'
                    value={get(mainData, "DocDate", "No date").slice(0, 10)}
                    disabled={true}
                  />
                </div>
                <div className='betweenMini'>
                  <p className='fio'>{t("Заказ принят")}</p>
                  <input
                    type='date'
                    className='inputDefault'
                    value={get(mainData, "DocDueDate", "No date").slice(0, 10)}
                    disabled={true}
                  />
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <p className='fio'>{t("Цена")}</p>
                <input
                  type='number'
                  placeholder='Текущая цена'
                  className='inputDefault'
                  value={get(mainData, "DocTotal", "Umumiy narx topilmadi")}
                  disabled={true}
                />
              </div>

              <p className='redTitle'>
                {"Долг:"}
                {get(mainData, "DocTotal", 0) - get(mainData, "PaidToDate", 0)}
                {"$"}
              </p>
            </div>

            <div className='betweenCard'>
              <div className='flex'>
                <p className='serialNumberTitle'>{t("Серийный номер")}</p>
              </div>
              <div className='serialMainCard'>
                {serialNumbersData.map((v, i) => {
                  return <p key={i}>{v}</p>;
                })}
              </div>
            </div>
            <div className='tableContainerMini2'>
              <p className='commetnTitle'>
                {t("Izoh")}:{" "}
                <span>{get(mainData, "Comments", "Komment topilmadi")}</span>
              </p>
            </div>

            <div className='tableContainerMini'>
              <p className='listTitle'>{t("Jadval")}</p>
              {isLoading ? (
                <div className='center2'>
                  <Loader />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>N</th>
                      <th>{t("Nomi")}</th>
                      <th>{t("Soni")}</th>
                      <th>{t("Dona narxi")}</th>
                      <th>{t("Bonus")}</th>
                      <th>{t("Umumiy bonus")}</th>
                      <th>{t("Bonussiz umumiy narxi")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {get(mainData, "DocumentLines", []).map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{v.ItemDescription}</td>
                          <td>{v.Quantity}</td>
                          <td>{v.Price}</td>
                          <td>{v.U_Cashback / v.Quantity}</td>
                          <td>{v.U_Cashback}</td>
                          <td>{v.LineTotal}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </Layout>

      <ErrorModal
        getRef={getErrorRef}
        title={t("Tolov qoshilmadi")}
        onConfirm={() => errorRef.current?.close()}
      />
      <SuccessModal
        getRef={getSuccessRef}
        onConfirm={() => successRef.current?.close()}
        title={t("Muvaffaqiyatli qoshildi")}
      />
    </ViewSalesStyle>
  );
};

export default ViewSales;
