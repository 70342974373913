import React, { useCallback, useRef, useState } from "react";
import DollarCourseStyle from "./DollarCourseStyle";
import Button from "../../components/Button";
import api from "../../api";
import { get } from "lodash";
import { ErrorModal, SuccessModal } from "../../components/Modal";
import Layout from "../../components/Layout";
import { t } from "i18next";

const DollarCourse = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with '0' if needed
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const [isLoading, setIsLoading] = useState(false);
  const [dollar, setDollar] = useState("");
  const [dateC, setDatec] = useState(formattedDate);

  const errorRef = useRef();
  const successRef = useRef();

  const getErrorRef = useCallback((ref) => {
    errorRef.current = ref;
  }, []);
  const getSuccessRef = useCallback((ref) => {
    successRef.current = ref;
  }, []);

  const createDollarCourse = () => {
    setIsLoading(true);
    api
      .post(`SBOBobService_SetCurrencyRate`, {
        Currency: "UZS",
        Rate: dollar, // kurs
        RateDate: dateC, // sana
      })
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "Заказ не создан")
        );
      });
  };

  return (
    <DollarCourseStyle>
      <Layout>
        <div className='container'>
          <div className='betweenCard'>
            <p className='fio'>Dollar</p>
            <input
              type='number'
              className='inputDefault'
              defaultValue={dollar}
              onInput={(v) => setDollar(v.target.value)}
            />

            <p className='fio'>{t("Дата")}</p>
            <input
              type='date'
              className='inputDefault'
              defaultValue={dateC}
              onInput={(v) => setDatec(v.target.value)}
            />
            <Button isLoading={isLoading} onClick={createDollarCourse}>
              {t("Добавить")}
            </Button>
          </div>
        </div>
      </Layout>

      <ErrorModal
        getRef={getErrorRef}
        onConfirm={() => errorRef.current?.close()}
      />

      <SuccessModal
        getRef={getSuccessRef}
        onConfirm={() => successRef.current?.close()}
        title={t("Muvaffaqiyatli qoshildi")}
      />
    </DollarCourseStyle>
  );
};

export default DollarCourse;
