import React, { useCallback, useEffect, useRef, useState } from "react";
import ViewItemStyle from "./ViewItemStyle";
import Button from "../../components/Button";
import api from "../../api";
import { get } from "lodash";
import { ErrorModal, PaymentModal } from "../../components/Modal";
import { useSelector } from "react-redux";
import { SuccessModal } from "../../components/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { t } from "i18next";
import { MdDone, MdQuestionMark } from "react-icons/md";

const ViewItem = () => {
  const navigate = useNavigate();
  const { getMe } = useSelector((state) => state.main);
  const location = useLocation();
  const CardCode = get(location, "state.CardCode", 0);
  const DocEntry = get(location, "state.DocEntry", 0);

  const errorRef = useRef();
  const paymentModalRef = useRef();
  const successRef = useRef();

  const getPaymentModalRef = useCallback((ref) => {
    paymentModalRef.current = ref;
  }, []);
  const getErrorRef = useCallback((ref) => {
    errorRef.current = ref;
  }, []);
  const getSuccessRef = useCallback((ref) => {
    successRef.current = ref;
  }, []);

  const [mainData, setMainData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [serialNumbersData, setSerialNumbersData] = useState([]);

  useEffect(() => {
    getInformationOfItem();
  }, []);

  const getInformationOfItem = () => {
    setIsLoading(true);
    api
      .get(
        `Invoices(${DocEntry})?$select=DocTotal,CardName,CardCode,DocDate,DocDueDate,DocEntry,DocNum,PaidToDate,DocumentLines,SalesPersonCode,U_SeriyaNomer,U_CloseDate,Comments,U_Vitrina,DocumentAdditionalExpenses`
      )
      .then((res) => {
        setMainData(JSON.parse(res.data));
        let serialN = get(JSON.parse(res.data), "U_SeriyaNomer", "");
        setSerialNumbersData(serialN.split("/r"));
        setIsLoading(false);
        console.log(JSON.parse(res.data).DocNum);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err getInformationOfItem", err);
      });
  };

  const createPayment = (sum, typeOfPayment, valuta, dollarCourse, dateC) => {
    setIsLoading(true);
    api
      .post(
        `$batch`,
        `
--batch_36522ad7-fc75-4b56-8c71-56071383e77c

Content-Type: multipart/mixed;boundary=changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http 
Content-Transfer-Encoding: binary 
Content-ID: 1 


POST /b1s/v2/IncomingPayments

${JSON.stringify({
  CardCode: CardCode,
  CashSum: sum,
  CashAccount:
    valuta === "UZS" && typeOfPayment === "U_CashAccount"
      ? getMe["U_CashUzsAccount"]
      : getMe[typeOfPayment],
  DocDate: dateC,
  DocCurrency: valuta,
  BankChargeAmount: 0,
  U_Bonus: typeOfPayment === "U_BonusAccount" ? "Bonus berildi" : null,
  U_SlpCode: getMe["SalesPersonCode"], // Do'kon kodi. Employee->SalesPersonCode
  PaymentInvoices: [
    {
      DocEntry: DocEntry,
      SumApplied: valuta === "UZS" ? Number(sum) / dollarCourse : Number(sum),
    },
  ],
})}


--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http 
Content-Transfer-Encoding: binary 
Content-ID: 3 

PATCH /b1s/v2/Invoices(${DocEntry})

${JSON.stringify({
  U_CloseDate: `${dateC}`,
})}

--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd--
--batch_36522ad7-fc75-4b56-8c71-56071383e77c--`,
        {
          headers: {
            "Content-Type":
              "multipart/mixed;boundary=batch_36522ad7-fc75-4b56-8c71-56071383e77c",
          },
        }
      )
      .then((res) => {
        const resData = get(res, "data", "");
        if (resData.includes("error")) {
          let message1 = res.data.split(`"message" : "`)[1];
          let message2 = message1.split(`"`);

          errorRef.current?.open(message2[0]);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          successRef.current?.open();
          getInformationOfItem();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "Заказ не создан")
        );
      });
  };

  return (
    <ViewItemStyle>
      <Layout>
        <div className='container'>
          <div className='centerCard'>
            <div className='betweenCard'>
              <p className='fio'>{t("ФИО")}</p>
              <input
                type='text'
                className='inputDefault'
                value={get(mainData, "CardName", "Клиент")}
                disabled={true}
              />

              <p className='fio'>{t("Бонус")}</p>
              <input
                type='text'
                className='inputDefault'
                value={get(
                  mainData,
                  "DocumentAdditionalExpenses[0].LineTotal",
                  "Bonus topilmadi"
                )}
                disabled={true}
              />

              <p className='greenTitle'>
                {t("Оплаченный")}: {get(mainData, "PaidToDate", 0).toFixed(3)}
                {"$"}
              </p>
            </div>

            <div className='betweenCard'>
              <div className='between'>
                <div className='betweenMini'>
                  <p className='fio'>{t("Дата")}</p>
                  <input
                    type='date'
                    className='inputDefault'
                    value={get(mainData, "DocDate", "No date").slice(0, 10)}
                    disabled={true}
                  />
                </div>
                <div className='betweenMini'>
                  <p className='fio'>{t("Заказ принят")}</p>
                  <input
                    type='date'
                    className='inputDefault'
                    value={get(mainData, "DocDueDate", "No date").slice(0, 10)}
                    disabled={true}
                  />
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <p className='fio'>{t("Цена")}</p>
                <input
                  type='number'
                  className='inputDefault'
                  value={get(mainData, "DocTotal", "Umumiy narx topilmadi")}
                  disabled={true}
                />
              </div>

              <p className='redTitle'>
                {t("Долг")}:
                {(
                  get(mainData, "DocTotal", 0) - get(mainData, "PaidToDate", 0)
                ).toFixed(3)}
                {"$"}
              </p>
            </div>

            <div className='betweenCard'>
              <div className='flex'>
                <p className='serialNumberTitle'>{t("Серийный номер")}</p>

                <Button
                  btnStyle={{ backgroundColor: "#243AB5" }}
                  onClick={() =>
                    paymentModalRef.current?.open(
                      Number(
                        (
                          get(mainData, "DocTotal", 0) -
                          get(mainData, "PaidToDate", 0)
                        ).toFixed(3)
                      )
                    )
                  }
                  isLoading={isLoading}
                  hoverBtnStyle={{ backgroundColor: "#243AB5" }}
                >
                  {t("Платить")}
                </Button>
              </div>
              <div className='serialMainCard'>
                {serialNumbersData.map((v, i) => {
                  return <p key={i}>{v}</p>;
                })}
              </div>

              <p className='vitrina'>
                {t("Vitrina")}:
                {get(mainData, "U_Vitrina", null) === null ? (
                  <MdQuestionMark />
                ) : (
                  <MdDone />
                )}
              </p>
            </div>
            <div className='tableContainerMini2'>
              <p className='commetnTitle'>
                {t("Izoh")}:{" "}
                <span>{get(mainData, "Comments", "Komment topilmadi")}</span>
              </p>
            </div>

            <div className='tableContainerMini'>
              <p className='listTitle'>{t("Jadval")}</p>
              <table>
                <thead>
                  <tr>
                    <th>N</th>
                    <th>{t("Nomi")}</th>
                    <th>{t("Soni")}</th>
                    <th>{t("Dona narxi")}</th>
                    <th>{t("Bonus")}</th>
                    <th>{t("Umumiy bonus")}</th>
                    <th>{t("Bonussiz umumiy narxi")}</th>
                  </tr>
                </thead>
                <tbody>
                  {get(mainData, "DocumentLines", []).map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{v.ItemDescription}</td>
                        <td>{v.Quantity}</td>
                        <td>{v.Price}</td>
                        <td>{v.U_Cashback / v.Quantity}</td>
                        <td>{v.U_Cashback}</td>
                        <td>{v.LineTotal}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Button
              btnStyle={{ marginTop: 50, width: 200 }}
              hoverBtnStyle={{ marginTop: 50, width: 200 }}
              isLoading={isLoading}
              onClick={() =>
                navigate("/returnProduct", {
                  state: {
                    CardCode,
                    DocEntry,
                    tableData: get(mainData, "DocumentLines", []),
                  },
                })
              }
            >
              {t("Mol qaytarish")}
            </Button>
          </div>
        </div>
      </Layout>

      <ErrorModal
        getRef={getErrorRef}
        title={t("Tolov qoshilmadi")}
        onConfirm={() => errorRef.current?.close()}
      />
      <PaymentModal
        getRef={getPaymentModalRef}
        onConfirm={(sum, typeOfPayment, valuta, dollarCourse, dateC) =>
          createPayment(sum, typeOfPayment, valuta, dollarCourse, dateC)
        }
        onClose={() => paymentModalRef.current?.close()}
      />
      <SuccessModal
        getRef={getSuccessRef}
        onConfirm={() => successRef.current?.close()}
        title={t("Muvaffaqiyatli qoshildi")}
      />
    </ViewItemStyle>
  );
};

export default ViewItem;
