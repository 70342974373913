import React, {useEffect, useState} from 'react';
import Styles from './Styles';
import SuccessImage from '../../../assets/images/warning.png';
import Modal from 'react-modal';
import {useTranslation} from 'react-i18next';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    border: 'none',
    width: '50%',
    minHeight: '40%',
  },
  overlay: {
    background: '#0000008D',
  },
};

const WarningModal = ({getRef, onConfirm = () => {}}) => {
  const {t} = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    const ref = {
      open: () => {
        setIsOpenModal(true);
      },
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}>
      <Styles>
        <div className="card">
          <img src={SuccessImage} alt="warning image" className="img" />
          <h2> {t('Внимание')}!</h2>
          <p className="title">
            {t(
              "Chiqib ketishdan oldin malumotlarni saqlab qo'ying Oynadan chiqib ketsangiz malumotlar ochib ketadi Chiqib ketishni istaysizmi",
            )}
          </p>
          <div className="centerCard">
            <button className="btnN" onClick={() => setIsOpenModal(false)}>
              {t('Yoq')}
            </button>
            <button
              className="btnY"
              onClick={() => {
                onConfirm();
                setIsOpenModal(false);
              }}>
              {t('Ha')}
            </button>
          </div>
        </div>
      </Styles>
    </Modal>
  );
};

export default WarningModal;
