import styled from "styled-components";
import colors from "../../assets/style/colors";

const WarehouseSytle = styled.div`
  .topTile {
    text-align: center;
    margin-top: 0;
    font-size: 22px;
    font-weight: bold;
  }
  table {
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    width: 100%;
    td,
    th {
      border: 1px solid ${colors.gray};
      padding: 5px;
      text-align: center;
    }
    .row {
      background-color: #ffffff;
    }
    .row:hover {
      background-color: #c9edff;
      cursor: pointer;
    }
  }
`;
export default WarehouseSytle;
