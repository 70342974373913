import React, { useEffect, useState } from "react";
import { Layout, Loader, Button } from "../../components";
import AllProductsStyle from "./AllProducts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MdDone } from "react-icons/md";
import { get } from "lodash";
import api from "../../api";

const AllProducts = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const { getMe } = useSelector((state) => state.main);

  const [clientName, setClientName] = useState("");
  const [dateC, setDateC] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [userCardCode, setUserCardCode] = useState("");
  const [userItemCode, setUserItemCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [productNameData, setProductNameData] = useState([]);
  const [data, setData] = useState({
    data: [],
    currentPage: 0, //20
    nextPage: 0, //30
    oldPage: 0,
  });
  
  const [data2, setData2] = useState({
    data2: [],
    currentPage2: 0, //20
    nextPage2: 0, //30
    oldPage2: 0,
  });

  useEffect(() => {
    search(0, 0);
    search2(0, 0);
    searchProduct("");
  }, []);

  const search = (currentPage = 0, oldDataPage = 0) => {
    setIsLoading(true);
    let cardCode = userCardCode.length > 0 ? userCardCode : `%25`;
    let itemCode = userItemCode.length > 0 ? userItemCode : `%25`;
    api
      .get(
        `SQLQueries('getInvoices2')/List?U_SeriyaNomer='%25${serialNumber.toLowerCase()}%25'&CardCode='${cardCode}'&DocDate='${dateC}%25'&U_SeriyaNomer2='%25${serialNumber.toUpperCase()}%25'&ItemCode='${itemCode}'&$skip=${currentPage}`,
        {
          headers: {
            Prefer: "odata.maxpagesize=10",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        const nextPage = Number(
          get(JSON.parse(res.data), "@odata.nextLink", "skip=0").split(
            "skip="
          )[1]
        );
        if (oldDataPage === 0) {
          setData({
            data: [...resData],
            currentPage: currentPage === 0 ? 0 : data.nextPage,
            nextPage: nextPage === 0 ? data.currentPage : nextPage,
            oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
          });
        } else {
          setData({
            data: [...resData],
            currentPage: data.nextPage - 20,
            nextPage: nextPage,
            oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const search2 = (currentPage2 = 0, oldDataPage2 = 0) => {
    setIsLoading2(true);
    let cardCode = userCardCode.length > 0 ? userCardCode : `%25`;
    let itemCode = userItemCode.length > 0 ? userItemCode : `%25`;

    api
      .get(
        `SQLQueries('getCreditMemos')/List?U_SeriyaNomer='%25${serialNumber.toLowerCase()}%25'&CardCode='${cardCode}'&DocDate='${dateC}%25'&U_SeriyaNomer2='%25${serialNumber.toUpperCase()}%25'&ItemCode='${itemCode}'&$skip=${currentPage2}`,
        {
          headers: {
            Prefer: "odata.maxpagesize=10",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        const nextPage2 = Number(
          get(JSON.parse(res.data), "@odata.nextLink", "skip=0").split(
            "skip="
          )[1]
        );
        if (oldDataPage2 === 0) {
          setData2({
            data2: [...resData],
            currentPage2: currentPage2 === 0 ? 0 : data2.nextPage2,
            nextPage2: nextPage2 === 0 ? data2.currentPage2 : nextPage2,
            oldPage2: nextPage2 === 0 ? data2.currentPage2 : nextPage2 - 20,
          });
        } else {
          setData2({
            data2: [...resData],
            currentPage: data2.currentPage2 - 10,
            nextPage: nextPage2,
            oldPage:
              nextPage2 < data2.currentPage2 ? nextPage2 : nextPage2 - 20,
          });
        }
        setIsLoading2(false);
      })
      .catch((err) => {
        console.log("err search2 ", err);
        setIsLoading2(false);
      });
  };

  const viewItem = (CardCode, DocEntry) => {
    navigation("/viewItem", { state: { CardCode, DocEntry } });
  };

  const viewSales = (CardCode, DocEntry) => {
    navigation("/viewSales", { state: { CardCode, DocEntry } });
  };

  const newDatas = () => {
    if (data.nextPage > data.currentPage) {
      search(data.nextPage, 0);
    } else {
      alert("boshqa malumot yoq");
    }
  };

  const oldData = () => {
    if (data.oldPage < 0) {
      alert("boshqa malumot yoq");
    } else {
      search(data.oldPage, 1);
    }
  };

  const newDatas2 = () => {
    if (data2.nextPage2 > data2.currentPage2) {
      search2(data2.nextPage2, 0);
    } else {
      alert("boshqa malumot yoq");
    }
  };

  const oldData2 = () => {
    if (data2.oldPage2 < 0) {
      alert("boshqa malumot yoq");
    } else {
      search2(data2.oldPage2, 1);
    }
  };

  const searchUser = (a = "") => {
    setClientName(a);
    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and (contains(CardName, '${a}') or contains(Phone1, '${a}'))`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setCustomerData(resData);
        // console.log("users=>", resData);
        if (a.includes(" , ")) {
          setUserCardCode(a.split(" , ")[1]);
        }
      })
      .catch((err) => {
        console.log("err searchUser:", err);
      });
  };

  const searchProduct = (a = "") => {
    api
      .get(
        `Items?$select=U_Bonus,ItemPrices,ItemCode,ItemName,QuantityOnStock&$filter=contains(ItemName,'${a}') and QuantityOnStock gt 0`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setProductNameData(resData);
      })
      .catch((err) => {
        console.log("err searchProduct:", err);
      });
  };

  const getProductInfoToState = (cardC) => {
    setUserItemCode(cardC);
  };

  // debounce

  useEffect(() => {
    const delay = 1000;
    let timeoutId;

    if (clientName) {
      timeoutId = setTimeout(() => {
        searchUser(clientName);
      }, delay);
    }

    return () => {
      // Agar component o'chirilsa, timeoutni bekor qilish
      clearTimeout(timeoutId);
    };
  }, [clientName]);

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    setClientName(newSearchTerm);
    if (e.target.value.length <= 0) {
      setUserCardCode("");
    }
  };

  return (
    <AllProductsStyle>
      <Layout>
        <div className='searchCard'>
          <div>
            <input
              type='text'
              list='client'
              className='inputUser'
              placeholder={t("Имя Клиента")}
              value={clientName}
              onChange={handleChange}
            />

            <datalist id='client'>
              {customerData.map((v, i) => (
                <option
                  key={i}
                  value={`${get(v, "CardName", "")} , ${get(
                    v,
                    "CardCode",
                    ""
                  )} , ${get(getMe, `GroupCodes.${v.GroupCode}`, "")}`}
                />
              ))}
            </datalist>

            <select
              name='select'
              className='input'
              onClick={(v) => getProductInfoToState(v.target.value)}
              // value={radarName}
            >
              <option value={""}>{t("Нет")}</option>
              {productNameData.map((v, i) => (
                <option key={i} value={get(v, "ItemCode", "")}>
                  {get(v, "ItemName", "")}
                </option>
              ))}
            </select>

            <input
              type='text'
              className='input'
              placeholder={t("Seria nomer")}
              defaultValue={serialNumber}
              onChange={(v) => setSerialNumber(v.target.value)}
            />

            <input
              type='date'
              className='input'
              defaultValue={dateC}
              onChange={(v) => setDateC(v.target.value)}
            />
          </div>
          <Button
            onClick={() => {
              search(0, 0);
              search2(0, 0);
            }}
            isLoading={isLoading || isLoading2}
          >
            {t("Поиск")}
          </Button>
        </div>

        <div className='tableMainCard'>
          <p className='productTitle'>{t("Продукты")}</p>
          {isLoading ? (
            <div className='loadingCard'>
              <Loader />
            </div>
          ) : (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th>{t("Код клиента")}</th>
                    <th>{t("Имя Клиента")}</th>
                    <th>{t("Vitrina")}</th>
                    <th>{t("Долг")}</th>
                    <th>{t("Деньги")}</th>
                    <th>{t("Дата продажи")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((v, i) => {
                    let date1 =
                      get(v, "DocDate", "").slice(0, 4) +
                      "-" +
                      get(v, "DocDate", "").slice(4, 6) +
                      "-" +
                      get(v, "DocDate", "").slice(6, 8);

                    return (
                      <tr
                        key={i}
                        onClick={() =>
                          viewItem(get(v, "CardCode", 0), get(v, "DocEntry", 0))
                        }
                        className='row'
                      >
                        <td>{get(v, "CardCode", "Код покупателя")}</td>
                        <td>{get(v, "CardName", "Имя покупателя")}</td>
                        <td>
                          {get(v, "U_Vitrina", null) === null ? "" : <MdDone />}
                        </td>
                        <td
                          className={
                            get(v, "DocTotal", 0) - get(v, "PaidToDate", 0) <= 0
                              ? "green"
                              : "red"
                          }
                        >
                          {(
                            get(v, "DocTotal", 0) - get(v, "PaidToDate", 0)
                          ).toFixed(3)}{" "}
                          USD
                        </td>
                        <td>{get(v, "DocTotal", "")} USD</td>
                        <td>{date1}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className='topCard'>
                <div className='flex'>
                  <Button className={"btn"} onClick={oldData}>
                    {"<"}
                  </Button>
                  <Button className={"btn"} onClick={newDatas}>
                    {">"}
                  </Button>
                </div>
                <Button
                  className={"btn"}
                  onClick={() => navigation("/createProducts")}
                >
                  {"+"}
                </Button>
              </div>
            </>
          )}
        </div>

        <div className='tableMainCard'>
          <p className='productTitle'>{t("Возврат")}</p>
          {isLoading2 ? (
            <div className='loadingCard'>
              <Loader />
            </div>
          ) : (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th>{t("Код клиента")}</th>
                    <th>{t("Имя Клиента")}</th>
                    <th>{t("Деньги")}</th>
                    <th>{t("Qaytarilgan sana")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data2.data2.map((v, i) => {
                    let date1 =
                      get(v, "DocDate", "").slice(0, 4) +
                      "-" +
                      get(v, "DocDate", "").slice(4, 6) +
                      "-" +
                      get(v, "DocDate", "").slice(6, 8);
                    return (
                      <tr
                        key={i}
                        onClick={() =>
                          viewSales(
                            get(v, "CardCode", 0),
                            get(v, "DocEntry", 0)
                          )
                        }
                        className='row'
                      >
                        <td>{get(v, "CardCode", "Код покупателя")}</td>
                        <td>{get(v, "CardName", "Имя покупателя")}</td>
                        <td>{get(v, "DocTotal", "")} USD</td>
                        <td>{date1}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className='topCard'>
                <div className='flex'>
                  <Button className={"btn"} onClick={oldData2}>
                    {"<"}
                  </Button>
                  <Button className={"btn"} onClick={newDatas2}>
                    {">"}
                  </Button>
                </div>
                <Button className={"btn"} onClick={() => navigation("/sales")}>
                  {"+"}
                </Button>
              </div>
            </>
          )}
        </div>
      </Layout>
    </AllProductsStyle>
  );
};

export default AllProducts;
