import styled from 'styled-components';
import colors from '../../assets/style/colors';

const LayoutStyle = styled.div`
  background: rgb(18, 77, 192);
  background: ${colors.blue2};
  min-height: 100vh;
  padding: 10px;
  padding-top: 35px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  .container {
    flex: 1;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 8px;
  }
`;
export default LayoutStyle;
