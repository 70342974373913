import React from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import DrawerMenuStyle from './DrawerMenuStyle';
import {useNavigate} from 'react-router-dom';
import {AiOutlineUnorderedList} from 'react-icons/ai';
import {useTranslation} from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import i18next from 'i18next';
import UZ from '../../assets/images/uz.png';
import RU from '../../assets/images/ru.png';

const DrawerMenu = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const data = [
    {
      title: t('Sotuvlar/Qaytarishlar'),
      navigate: 'allProducts',
      isActive: false,
    },
    {
      title: t('Qarzlar'),
      navigate: 'debts',
      isActive: false,
    },
    {
      title: t('Bonus berish'),
      navigate: 'giveBonus',
      isActive: false,
    },
    {
      title: t('Ombor'),
      navigate: 'warehouse',
      isActive: false,
    },
    {
      title: t('Dollar kursi'),
      navigate: 'dollarCourse',
      isActive: false,
    },
    {
      title: t('Kunlik kirim'),
      navigate: 'todaysSale',
      isActive: false,
    },
    {
      title: t('Chiqish'),
      navigate: 'login',
      isActive: false,
    },
    // {
    //   title: t("Kanvertatsiya"),
    //   navigate: "convertation",
    //   isActive: false,
    // },
  ];

  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };

  const goToScreen = n => {
    navigate(`/${n}`);
  };

  const changeLanguage = ln => {
    AsyncStorage.setItem('lan', ln);
    i18next.changeLanguage(ln);
  };

  return (
    <DrawerMenuStyle>
      <button onClick={toggleDrawer} className="listBtn">
        <AiOutlineUnorderedList />
      </button>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
        <div className="container">
          <div className="drawer">
            <p className="menuTitle">{t('Меню')}</p>

            {data.map((v, i) => {
              return (
                <button
                  key={i}
                  className={'btn'}
                  onClick={() => goToScreen(v.navigate)}>
                  {v.title}
                </button>
              );
            })}

            <div className="between">
              <button className="btnFlag" onClick={() => changeLanguage('uz')}>
                <img className="flag" src={UZ} alt="uz" />
              </button>
              <button className="btnFlag" onClick={() => changeLanguage('ru')}>
                <img className="flag" src={RU} alt="uz" />
              </button>
            </div>
          </div>
          <p className="sapTitle">SAP</p>
        </div>
      </Drawer>
    </DrawerMenuStyle>
  );
};

export default DrawerMenu;
