import styled from "styled-components";
import colors from "../../assets/style/colors";

const DrawerStyle = styled.div`
  position: absolute;
  top: 5px;
  left: 10px;
  .container {
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .drawer {
    background-color: #ffffff;
    width: 100%;

    .btn {
      width: 100%;
      height: 40px;
      background-color: #d7dee7;
      border: none;
      margin-bottom: 10px;
      border-radius: 10px;
      color: #000000;
      transition: 0.2s;
    }

    .btn:hover {
      box-shadow: 2px 3px 10px #3a3c469a;
    }
    .menuTitle {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      color: ${colors.blue2};
    }
  }
  .listBtn {
    width: 50px;
    height: 25px;
    background-color: white;
    box-shadow: 5px 5px 10px #00000043;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .flag {
    width: 40px;
    height: 20px;
  }
  .between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .btnFlag {
    width: 46%;
    height: 40px;
    background-color: #d7dee7;
    border: none;
    margin-bottom: 10px;
    border-radius: 10px;
    text-align: center;
  }
  .btnFlag:hover {
    box-shadow: 2px 3px 10px #3a3c469a;
  }
  .sapTitle {
    font-size: 30px;
    text-align: center;
    color: ${colors.blue2};
    font-weight: bold;
    width: 100%;
  }
`;
export default DrawerStyle;
