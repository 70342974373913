import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Layout } from "../../components";
import GiveBonusSytle from "./GiveBonusStyle";
import PaymentModa from "./PaymentModal";
import api from "../../api";
import { useSelector } from "react-redux";
import { ErrorModal, SuccessModal } from "../../components/Modal";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const GiveBonus = () => {
  const { t } = useTranslation();

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-01`;

  const { getMe } = useSelector((state) => state.main);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [bonusPrice, setBonusPrice] = useState(0);
  const [cardC, setCardC] = useState("");
  const [data, setData] = useState([]);

  const successRef = useRef();
  const errorRef = useRef();
  const paymentBonusRef = useRef();

  const getSuccessRef = useCallback((ref) => {
    successRef.current = ref;
  }, []);
  const getPaymentBonusRef = useCallback((ref) => {
    paymentBonusRef.current = ref;
  }, []);
  const getErrorRef = useCallback((ref) => {
    errorRef.current = ref;
  }, []);

  const openBonusPayment = () => {
    paymentBonusRef.current?.open();
  };

  const createPayment = (
    sum,
    typeOfPayment,
    valuta,
    dollarCourse,
    dateC,
    userCardCode,
    monthS,
    sum2
  ) => {
    setIsLoading(true);
    api
      .post(`VendorPayments`, {
        CardCode: getMe["U_BonusAccount"],
        DocType: "rAccount",
        CashSum: sum2,
        CashAccount:
          valuta === "UZS" && typeOfPayment === "U_CashAccount"
            ? getMe["U_CashUzsAccount"]
            : getMe[typeOfPayment],
        BankChargeAmount: 0,
        BankChargeAmountInSC: 0,
        DocDate: dateC,
        DocCurrency: valuta,
        U_Bonus: "Bonus berildi",
        U_Oylar: monthS,
        U_BP: userCardCode, // Mijoz kodi. BusinessPartner->CardCode
        U_SlpCode: getMe["SalesPersonCode"], // Do'kon kodi. Employee->SalesPersonCode
        PaymentAccounts: [
          {
            AccountCode: getMe["U_BonusAccount"],
            SumPaid: sum2,
            U_Clients: userCardCode,
          },
        ],
      })
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "Заказ не создан")
        );
      });
  };

  const searchUser = (a = "") => {
    setCustomer(a);

    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1&$filter=CardType eq 'cCustomer' and (contains(CardName, '${a}') or contains(Phone1, '${a}'))`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setCustomerData(resData);
        if (a.includes(" , ")) {
          getBonus(a.split(" , ")[1]);
          getBonuses(a.split(" , ")[1]);
        }
      })
      .catch((err) => {
        console.log("err searchUser:", err);
      });
  };

  const getBonus = (cardC) => {
    setIsLoading(true);
    api
      .get(
        `SQLQueries('getBonus')/List?CardCode='${cardC}'&Date='${formattedDate}T00:00:00Z'`
      )
      .then((res) => {
        setIsLoading(false);
        const resData = get(JSON.parse(res.data), "value", []);
        // setBonusPrice(resData[]);
        // let album = resData.map((v, i) => {
        //   return Object.values(v)[1];
        // });
        let bonusSum = 0;
        for (let i = 0; i < resData.length; i++) {
          if (
            resData[i].Tur === "Invoices" ||
            resData[i].Tur === "ServiceInvoices"
          ) {
            bonusSum = bonusSum + resData[i].Bonus;
          } else {
            bonusSum = bonusSum - resData[i].Bonus;
          }
        }
        setBonusPrice(bonusSum);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err searchUser:", err);
      });
  };

  useEffect(() => {
    getBonuses();
  }, []);

  const getBonuses = (cc) => {
    api
      .get(
        `SQLQueries('getBonusDetails')/List?CardCode='${cc}'&Date='${formattedDate}T00:00:00Z'`,
        {
          headers: {
            Prefer: "odata.maxpagesize=1000",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []).sort(
          (e1, e2) => e2.DocEntry - e1.DocEntry
        );
        setData(resData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //debounce
  useEffect(() => {
    const delay = 1000;
    let timeoutId;

    if (customer) {
      timeoutId = setTimeout(() => {
        searchUser(customer);
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [customer]);

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    setCustomer(newSearchTerm);
  };

  return (
    <Layout>
      <GiveBonusSytle>
        <div className='container'>
          <div className='container2'>
            <div>
              <p className='topTitle'>{t("Клиент")}</p>
              <input
                type='text'
                list='client'
                className='input'
                value={customer}
                onChange={handleChange}
              />
              <datalist id='client'>
                {customerData.map((v, i) => (
                  <option
                    key={i}
                    value={`${get(v, "CardName", "")} , ${get(
                      v,
                      "CardCode",
                      ""
                    )}`}
                  />
                ))}
              </datalist>
            </div>

            <div>
              <p className='topTitle'>{t("Bonus")}</p>
              <input
                type='text'
                className='inputDefault'
                value={bonusPrice.toFixed(3)}
                disabled={true}
              />
            </div>

            <div>
              <p></p>
              <Button
                onClick={openBonusPayment}
                isLoading={isLoading}
                btnStyle={{ width: 250 }}
              >
                {t("Bonus berish")}
              </Button>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <p className='titleTable'>{t("Jadval")}</p>
            <table>
              <thead>
                <tr>
                  <th>N</th>
                  <th>{t("Kod")}</th>
                  <th>{t("Tur")}</th>
                  <th>{t("Деньги")}</th>
                  <th>{t("Bonus")}</th>
                  <th>{t("Дата")}</th>
                  <th>{t("Yopilish sanasi")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((v, i) => {
                  let DocDate =
                    get(v, "DocDate", "").slice(0, 4) +
                    "-" +
                    get(v, "DocDate", "").slice(4, 6) +
                    "-" +
                    get(v, "DocDate", "").slice(6, 8);
                  let U_CloseDate = get(v, "U_CloseDate", "") || "";
                  U_CloseDate =
                    U_CloseDate.slice(0, 4) +
                    "-" +
                    U_CloseDate.slice(4, 6) +
                    "-" +
                    U_CloseDate.slice(6, 8);
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{v.DocNum}</td>

                      <td>
                        {v.Tur === "Invoices" ? "Sotuv" : ""}
                        {v.Tur === "ServiceInvoices" ? "Eski sotuv" : ""}
                        {v.Tur === "OutgoingPayments" ? "Chiqim to'lo'v" : ""}
                        {v.Tur === "IncomingPayments" ? "Kirim to'lo'v" : ""}
                        {v.Tur === "CreditMemos" ? "Mol qaytarish" : ""}
                      </td>
                      <td>{v.DocTotal}</td>
                      <td>
                        {v.Tur === "Invoices" || v.Tur === "ServiceInvoices"
                          ? v.Bonus
                          : -v.Bonus}
                      </td>
                      <td>{DocDate}</td>
                      <td>
                        {v.Tur === "Invoices" || v.Tur === "ServiceInvoices"
                          ? U_CloseDate
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </GiveBonusSytle>

      <>
        <PaymentModa
          getRef={getPaymentBonusRef}
          onConfirm={(
            sum,
            typeOfPayment,
            valuta,
            dollarCourse,
            dateC,
            userCardCode,
            monthS,
            sum2
          ) =>
            createPayment(
              sum,
              typeOfPayment,
              valuta,
              dollarCourse,
              dateC,
              userCardCode,
              monthS,
              sum2
            )
          }
          onClose={() => paymentBonusRef.current?.close()}
        />
        <SuccessModal getRef={getSuccessRef} title={t("Бонус успешно выдан")} />
        <ErrorModal getRef={getErrorRef} />
      </>
    </Layout>
  );
};

export default GiveBonus;
