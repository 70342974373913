import styled from "styled-components";
import colors from "../../assets/style/colors";

const AllProductsStyle = styled.div`
  .topCard {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 10px;
    .flex {
      display: flex;
      width: 80px;
      justify-content: space-between;
    }
    .btn {
      width: 35px;
      height: 35px;
      padding: 0;
      border-radius: 50px;
    }
  }
  .table {
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    width: 100%;
    td,
    th {
      border: 1px solid ${colors.gray};
      padding: 5px;
    }
    .row {
      background-color: #ffffff;
    }
    .row:hover {
      background-color: #c9edff;
      cursor: pointer;
    }
  }
  .searchCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .input {
      border: 2px solid ${colors.blue2};
      font-size: 14px;
      padding: 10px;
      border-radius: 5px;
      color: ${colors.blue1};
      width: 200px;
      outline-color: #0011cf;
      margin-right: 20px;
    }
  }
  .productTitle {
    font-size: 25px;
    font-weight: bold;
    color: #434343;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .red {
    color: red;
  }

  .inputUser {
    border: 2px solid ${colors.blue2};
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 350px;
    outline-color: #0011cf;
    margin-right: 20px;
  }
  .loadingCard {
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    .animationLoader {
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 40px;
      margin: 0;
      padding: 0;
      animation: animationForLoader 5s infinite;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @keyframes animationForLoader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .tableMainCard {
    height: 500px;
  }
`;
export default AllProductsStyle;
