import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout, Loader } from "../../components";
import TodaysSaleSytle from "./TodaysSaleStyle";
import api from "../../api";
import { ErrorModal, SuccessModal } from "../../components/Modal";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TodaysSale = () => {
  const { t } = useTranslation();

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const { getMe } = useSelector((state) => state.main);

  const [customerData, setCustomerData] = useState([]);
  const [customerData2, setCustomerData2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const successRef = useRef();
  const errorRef = useRef();

  const getSuccessRef = useCallback((ref) => {
    successRef.current = ref;
  }, []);

  const getErrorRef = useCallback((ref) => {
    errorRef.current = ref;
  }, []);

  useEffect(() => {
    getTodaysSale(formattedDate);
    getTodaysSale2(formattedDate);
  }, []);

  const getTodaysSale = (givenDate) => {
    setIsLoading(true);
    api
      .get(
        `SQLQueries('getIncomingSum2')/List?date='${givenDate}T00:00:00Z'&SlpCode=${getMe["SalesPersonCode"]}&account1='${getMe["U_CashAccount"]}'&account2='${getMe["U_CashUzsAccount"]}'&account3='${getMe["U_CardAccount"]}'&account4='${getMe["U_TransAccount"]}'&bonusAccount='${getMe["U_BonusAccount"]}'`,
        {
          headers: {
            Prefer: "odata.maxpagesize=5000",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setCustomerData(resData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err getTodaysSale:", err);
        setIsLoading(false);
      });
  };

  const getTodaysSale2 = (givenDate) => {
    setIsLoading2(true);
    api
      .get(
        `SQLQueries('getIncomingSum2Details')/List?date='${givenDate}T00:00:00Z'&SlpCode=${getMe["SalesPersonCode"]}&account1='${getMe["U_CashAccount"]}'&account2='${getMe["U_CashUzsAccount"]}'&account3='${getMe["U_CardAccount"]}'&account4='${getMe["U_TransAccount"]}'&bonusAccount='${getMe["U_BonusAccount"]}'`,
        {
          headers: {
            Prefer: "odata.maxpagesize=5000",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        const sortedData = resData.sort((a, b) => {
          return (
            Number(get(a, "Hujjat raqami", 0)) -
            Number(get(b, "Hujjat raqami", 0))
          );
        });
        setCustomerData2(sortedData);
        setIsLoading2(false);
      })
      .catch((err) => {
        console.log("err getTodaysSale2:", err);
        setIsLoading2(false);
      });
  };

  return (
    <Layout>
      <TodaysSaleSytle>
        <div className='container'>
          <div className='card'>
            <input
              type='date'
              className='input'
              defaultValue={formattedDate}
              onChange={(v) => {
                getTodaysSale(v.target.value);
                getTodaysSale2(v.target.value);
              }}
            />

            <div className='tableCard'>
              <p className='topTitle'>{t("Kunlik kirim")}</p>

              {isLoading ? (
                <div className='loadingCard'>
                  <Loader />
                </div>
              ) : (
                <table className='table'>
                  <thead>
                    <tr>
                      <th>{t("Chiqim")}</th>
                      <th>{t("Kirim")}</th>
                      <th>{t("Tur")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerData.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{v.Credit}</td>
                          <td>{v.Debit}</td>
                          <td>{v.Tur}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>

            <div className='tableCard'>
              <p className='topTitle'>{t("Kunlik kirim tafsilotlari")}</p>

              {isLoading2 ? (
                <div className='loadingCard'>
                  <Loader />
                </div>
              ) : (
                <table className='table'>
                  <thead>
                    <tr>
                      <th>{t("Hujjat raqami")}</th>
                      <th>{t("Kontr hisob")}</th>
                      <th>{t("Chiqim")}</th>
                      <th>{t("Kirim")}</th>
                      <th>{t("Дата")}</th>
                      <th>{t("Tur")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerData2.map((v, i) => {
                      let date1 =
                        get(v, "Sana", "").slice(0, 4) +
                        "-" +
                        get(v, "Sana", "").slice(4, 6) +
                        "-" +
                        get(v, "Sana", "").slice(6, 8);
                      return (
                        <tr key={i}>
                          <td>{get(v, "Hujjat raqami", "")}</td>
                          <td>{get(v, "Kontr hisob", "")}</td>
                          <td>{v.Kredit}</td>
                          <td>{v.Debit}</td>
                          <td>{date1}</td>
                          <td>
                            {get(v, "Hujjat turi", "") === "24"
                              ? "Kirim to'lov"
                              : get(v, "Hujjat turi", "") === "13"
                              ? "Sotuv"
                              : get(v, "Hujjat turi", "") === "14"
                              ? "Mol qaytarish"
                              : get(v, "Hujjat turi", "") === "46"
                              ? "Chiqim to'lov"
                              : "Buxgalter operatsiyasi"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </TodaysSaleSytle>
      <SuccessModal getRef={getSuccessRef} title={t("Бонус успешно выдан")} />
      <ErrorModal getRef={getErrorRef} />
    </Layout>
  );
};

export default TodaysSale;
