import React from "react";
import LayoutStyle from "./LayoutStyle";
import DrawerMenu from "../DrawerMenu";

const Layout = ({ children }) => {
  return (
    <LayoutStyle>
      <DrawerMenu />
      <div className='container'>{children}</div>
    </LayoutStyle>
  );
};

export default Layout;
