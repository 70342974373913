import styled from "styled-components";
import colors from "../../assets/style/colors";

const TodaysSaleSytle = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    .topTitle {
      margin: 0;
    }
  }
  .input {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 250px;
    outline: none;
  }
  .inputDefault {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 250px;
    outline: none;
    background-color: #dbdfe0;
  }
  .table {
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 50px;
    td,
    th {
      border: 1px solid ${colors.gray};
      padding: 5px;
      text-align: center;
    }
    .row {
      background-color: #ffffff;
    }
    .row:hover {
      background-color: #c9edff;
      cursor: pointer;
    }
  }
  .card {
    width: 100%;
  }
  .topTitle {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: ${colors.blue1};
  }
  .loadingCard {
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tableCard {
    min-height: 300px;
  }
`;
export default TodaysSaleSytle;
