import React, { useEffect } from "react";
import LaunchStyle from "./LaunchStyle";
import { useNavigate } from "react-router-dom";

const Launch = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  }, []);

  return (
    <LaunchStyle>
      <div className='container'>
        <p className='text'>SAP</p>
      </div>
    </LaunchStyle>
  );
};

export default Launch;
