import styled from "styled-components";
import colors from "../../assets/style/colors";

const MainStyle = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .topTitle {
      margin: 0;
    }
  }
  .container2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .input {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 250px;
    outline: none;
  }
  .inputDefault {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 250px;
    outline: none;
    background-color: #dbdfe0;
  }
  table {
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    width: 100%;
    td,
    th {
      border: 1px solid ${colors.gray};
      padding: 5px;
      text-align: center;
    }
    .row {
      background-color: #ffffff;
    }
    .row:hover {
      background-color: #c9edff;
      cursor: pointer;
    }
  }
  .titleTable {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
  }
  .arrow {
    margin: 0 10px;
    transform: translateY(-10px);
    font-size: 15px;
    width: 50px;
  }
`;
export default MainStyle;
