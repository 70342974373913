import React, { memo, useEffect, useState } from "react";
import PaymentModalStyle from "./PaymentModalStyle";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import api from "../../../api";
import { AiOutlineArrowRight } from "react-icons/ai";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const PaymentModal = ({ getRef, onConfirm = () => {}, onClose = () => {} }) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [number, setNumber] = useState(0);
  const [kurs1, setKurs1] = useState("");
  const [sum1, setSum1] = useState("");
  const [cSum1, setCsum1] = useState("");

  const [sum2, setSum2] = useState("");
  const [cSum2, setCsum2] = useState("");

  useEffect(() => {
    const ref = {
      open: (num) => {
        setIsOpenModal(true);
        setNumber(num);
      },
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
    getUSD();
  }, []);

  const getUSD = () => {
    api
      .post(`SBOBobService_GetCurrencyRate`, {
        Currency: "UZS",
        Date: formattedDate,
      })
      .then((res) => {
        setKurs1(JSON.parse(res.data));
        console.log(JSON.parse(res.data));
      })
      .catch((err) => {
        console.log("err getUSD", err);
      });
  };

  const dollarToSumC = (num) => {
    setSum1(num);
    setCsum1(kurs1 * num);
  };

  const sumToDollarC = (num) => {
    setSum2(num);
    setCsum2(num / kurs1);
    console.log(num);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel='Example Modal'
      ariaHideApp={false}
    >
      <PaymentModalStyle>
        <div className='card'>
          {number === 1 ? (
            <>
              <p className='maintitle'>
                Dollar
                <span className='arrow'>
                  <AiOutlineArrowRight />
                </span>
                Sum
              </p>
              <p className='inputTitle'>Kurs</p>
              <input
                type='number'
                className='input'
                defaultValue={kurs1}
                onChange={(v) => setKurs1(Number(v.target.value))}
              />

              <p className='inputTitle'>Dollar kiritish</p>
              <input
                type='number'
                className='input'
                defaultValue={sum1}
                onChange={(v) => dollarToSumC(Number(v.target.value))}
              />

              <p className='inputTitle'>Konvertatsiya summasi (Sum)</p>
              <input
                type='number'
                className='input'
                value={cSum1}
                onChange={(v) => setCsum1(Number(v.target.value))}
              />

              <div className='between'>
                <Button
                  btnStyle={{ width: 100 }}
                  onClick={() => setIsOpenModal(false)}
                >
                  {t("Нет")}
                </Button>
                <Button btnStyle={{ width: 100 }}>{t("Да")}</Button>
              </div>
            </>
          ) : (
            <>
              <p className='maintitle'>
                Sum
                <span className='arrow'>
                  <AiOutlineArrowRight />
                </span>
                Dollar
              </p>

              <p className='inputTitle'>Kurs</p>
              <input
                type='number'
                className='input'
                defaultValue={kurs1}
                onChange={(v) => setKurs1(Number(v.target.value))}
              />

              <p className='inputTitle'>Sum kiritish</p>
              <input
                type='number'
                className='input'
                defaultValue={sum2}
                onChange={(v) => sumToDollarC(Number(v.target.value))}
              />

              <p className='inputTitle'>Konvertatsiya summasi (Dollar)</p>
              <input
                type='number'
                className='input'
                value={cSum2}
                onChange={(v) => setCsum2(Number(v.target.value))}
              />

              <div className='between'>
                <Button
                  btnStyle={{ width: 100 }}
                  onClick={() => setIsOpenModal(false)}
                >
                  {t("Нет")}
                </Button>
                <Button btnStyle={{ width: 100 }}>{t("Да")}</Button>
              </div>
            </>
          )}
        </div>
      </PaymentModalStyle>
    </Modal>
  );
};

export default memo(PaymentModal);
