import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Layout, Loader } from "../../components";
import DebtsSytle from "./DebtsStyle";
import api from "../../api";
import { get } from "lodash";
import { AiOutlineReload } from "react-icons/ai";
import PaymentDebts from "./PaymentDebts";
import { useSelector } from "react-redux";
import { ErrorModal, SuccessModal } from "../../components/Modal";
import { useTranslation } from "react-i18next";
import numberWithSpaces from "../../helpers/numberWithSpaces";

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with '0' if needed
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;

const Debts = () => {
  const { t } = useTranslation();
  const paymentDebts = useRef();
  const errorRef = useRef();
  const successRef = useRef();
  const { getMe } = useSelector((state) => state.main);

  const [isTodayDebt, setisTodayDebt] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    data: [],
    currentPage: 0, //20
    nextPage: 0, //30
    oldPage: 0,
  });

  const getPaymentDebts = useCallback((ref) => {
    paymentDebts.current = ref;
  }, []);
  const getSuccessRef = useCallback((ref) => {
    successRef.current = ref;
  }, []);
  const getErrorRef = useCallback((ref) => {
    errorRef.current = ref;
  }, []);

  useEffect(() => {
    getDebts(0, 0, true);
  }, []);

  const getDebts = (currentPage = 0, oldDataPage = 0, today = true) => {
    setIsLoading(true);

    api
      .get(
        !today
          ? `SQLQueries('getDebt')/List?startDate='${formattedDate}T00:00:00Z'&endDate='${formattedDate}T00:00:00Z'&$skip=${currentPage}`
          : `SQLQueries('getDebt')/List?startDate='1990-01-01T00:00:00Z'&endDate='2100-12-12T00:00:00Z'&$skip=${currentPage}`,
        {
          headers: {
            Prefer: "odata.maxpagesize=10",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        const nextPage = Number(
          get(JSON.parse(res.data), "@odata.nextLink", "skip=0").split(
            "skip="
          )[1]
        );

        if (oldDataPage === 0) {
          setData({
            data: [...resData],
            currentPage: currentPage === 0 ? 0 : data.nextPage,
            nextPage: nextPage === 0 ? data.currentPage : nextPage,
            oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
          });
        } else {
          setData({
            data: [...resData],
            currentPage: data.nextPage - 20,
            nextPage: nextPage,
            oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const newDatas = () => {
    if (data.nextPage > data.currentPage) {
      getDebts(data.nextPage, 0, isTodayDebt);
    } else {
      alert("boshqa malumot yoq");
    }
  };

  const oldData = () => {
    if (data.oldPage < 0) {
      alert("boshqa malumot yoq");
    } else {
      getDebts(data.oldPage, 1, isTodayDebt);
    }
  };

  const changeActiveBtn = (t) => {
    setisTodayDebt(t);
    getDebts(0, 0, t);
  };

  const createPayment = (
    sum,
    typeOfPayment,
    valuta,
    dollarCourse,
    dateC,
    userCardCode,
    debtsData
  ) => {
    setIsLoading(true);
    let umumiySum = valuta === "UZS" ? Number(sum) / dollarCourse : Number(sum);
    let dataSum = [];
    let allDebts = 0;
    let tolanganSum = 0;

    for (let i = 0; i < debtsData.length; i++) {
      let debtQ = debtsData[i].DocTotal - debtsData[i].PaidToDate;
      if (umumiySum - debtQ > 0) {
        dataSum.push({
          DocEntry: debtsData[i].DocEntry,
          SumApplied: debtQ,
        });
        umumiySum = umumiySum - debtQ;
      } else if (umumiySum !== 0) {
        dataSum.push({
          DocEntry: debtsData[i].DocEntry,
          SumApplied: umumiySum,
        });
        umumiySum = 0;
      }
      allDebts = allDebts + (debtsData[i].DocTotal - debtsData[i].PaidToDate);
      tolanganSum = tolanganSum + debtsData[i].PaidToDate;
    }

    api
      .post(
        `$batch`,
        `
--batch_36522ad7-fc75-4b56-8c71-56071383e77c

Content-Type: multipart/mixed;boundary=changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http
Content-Transfer-Encoding: binary
Content-ID: 1

POST /b1s/v2/IncomingPayments

  ${JSON.stringify({
    CardCode: userCardCode,
    CashSum: sum,
    CashAccount:
      valuta === "UZS" && typeOfPayment === "U_CashAccount"
        ? getMe["U_CashUzsAccount"]
        : getMe[typeOfPayment],
    DocDate: dateC,
    DocCurrency: valuta,
    BankChargeAmount: 0,
    U_Bonus: typeOfPayment === "U_BonusAccount" ? "Bonus berildi" : null,
    PaymentInvoices: dataSum,
  })}

        ${dataSum.map((v, i) => {
          return `
--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http
Content-Transfer-Encoding: binary
Content-ID: ${i + 2}

PATCH /b1s/v2/Invoices(${v.DocEntry})

{
  "U_CloseDate": "${dateC}"
}`;
        })}

--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd--
--batch_36522ad7-fc75-4b56-8c71-56071383e77c--`,
        {
          headers: {
            "Content-Type":
              "multipart/mixed;boundary=batch_36522ad7-fc75-4b56-8c71-56071383e77c",
          },
        }
      )
      .then((res) => {
        const resData = get(res, "data", "");
        if (resData.includes("error")) {
          let message1 = res.data.split(`"message" : "`)[1];
          let message2 = message1.split(`"`);
          errorRef.current?.open(message2[0]);
        } else {
          successRef.current?.open();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "Заказ не создан")
        );
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <DebtsSytle>
        <div className='topCard'>
          <div>
            <button
              className={isTodayDebt ? "btnActive" : "btn"}
              onClick={() => changeActiveBtn(true)}
            >
              {t("Все долги")}
            </button>
            <button
              className={!isTodayDebt ? "btnActive" : "btn"}
              onClick={() => changeActiveBtn(false)}
            >
              {t("Сегодняшние долги")}
            </button>
          </div>
          <div>
            <button
              className={"btn"}
              onClick={() => paymentDebts.current?.open()}
            >
              {t("Qarzga tolash")}
            </button>
          </div>
        </div>
        <div className='center'>
          {isLoading ? (
            <div className='loadingCard'>
              <Loader />
            </div>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th>{t("Код клиента")}</th>
                  <th>{t("Имя Клиента")}</th>
                  <th>{t("Telefon")}</th>
                  <th>{t("Dokon")}</th>

                  <th>{t("Umumiy qarz")}</th>
                </tr>
              </thead>

              <tbody>
                {data.data.map((v, i) => {
                  return (
                    <tr key={i} className='row'>
                      <td>{get(v, "CardCode", "Код покупателя")}</td>
                      <td>{get(v, "CardName", "Имя покупателя")}</td>
                      <td>{get(v, "Phone1", "")}</td>
                      <td>{get(v, "GroupName", "")}</td>

                      <td>
                        {numberWithSpaces(
                          (
                            get(v, "SumDocTotal", 0) -
                            get(v, "SumPaidToDate", 0)
                          ).toFixed(2)
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div className='topCard'>
            <div className='flex'>
              <Button className={"btn"} onClick={oldData}>
                {"<"}
              </Button>
              <Button className={"btn"} onClick={newDatas}>
                {">"}
              </Button>
            </div>
          </div>
        </div>
      </DebtsSytle>
      <PaymentDebts
        getRef={getPaymentDebts}
        onConfirm={(
          sum,
          typeOfPayment,
          valuta,
          dollarCourse,
          dateC,
          userCardCode,
          debtsData
        ) =>
          createPayment(
            sum,
            typeOfPayment,
            valuta,
            dollarCourse,
            dateC,
            userCardCode,
            debtsData
          )
        }
        onClose={() => paymentDebts.current?.close()}
      />

      <SuccessModal
        getRef={getSuccessRef}
        title={t("Muvaffaqiyatli qoshildi")}
      />
      <ErrorModal getRef={getErrorRef} />
    </Layout>
  );
};

export default Debts;
