import uz from "./uz.json";
import ru from "./ru.json";

export default {
  ru: {
    main: ru,
  },
  uz: {
    main: uz,
  },
};
