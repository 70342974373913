import styled from "styled-components";
import colors from "../../assets/style/colors";

const DebtsSytle = styled.div`
  .topCard {
    display: flex;
    justify-content: space-between;
    .btn {
      border: none;
      background-color: #ffffff;
      box-shadow: 1px 5px 7px #0000003e;
      border-radius: 10px;
      padding: 10px 20px;
      margin: 5px;
      font-weight: bold;
    }
    .btnActive {
      border: none;
      background-color: #3166e2;
      box-shadow: 1px 5px 7px #0000003e;
      border-radius: 10px;
      padding: 10px 20px;
      margin: 5px;
      color: #ffffff;
      font-weight: bold;
    }
  }

  .table {
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    width: 100%;
    td,
    th {
      border: 1px solid ${colors.gray};
      padding: 5px;
      text-align: center;
    }
    .row {
      background-color: #ffffff;
    }
    .row:hover {
      background-color: #c9edff;
      /* cursor: pointer; */
    }
  }
  .searchCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .input {
      border: 2px solid ${colors.blue2};
      font-size: 14px;
      padding: 10px;
      border-radius: 5px;
      color: ${colors.blue1};
      width: 200px;
      outline-color: #0011cf;
      margin-right: 20px;
    }
  }
  .productTitle {
    font-size: 25px;
    font-weight: bold;
    color: #434343;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .flex {
    display: flex;
    /* justify-content: space-between; */

    .btn {
      width: 50px !important;
    }
  }
  .titleLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 375px;
  }
  .loadingCard {
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default DebtsSytle;
