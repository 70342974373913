import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import WarehouseSytle from "./WarehouseStyle";
import { get } from "lodash";
import api from "../../api";
import { useTranslation } from "react-i18next";

const Warehouse = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => {
    getWarehouse();
  }, []);

  const getWarehouse = () => {
    api
      .get(`SQLQueries('getItemsByWarehouses')/List`, {
        headers: {
          Prefer: "odata.maxpagesize=200",
        },
      })
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setData(resData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Layout>
      <WarehouseSytle>
        <div className='mainContainer'>
          <p className='topTile'>{t("Ombor")}</p>
          <table>
            <thead>
              <tr>
                <th>N</th>
                <th>{t("Mol kodi")}</th>
                <th>{t("Mol nomi")}</th>
                <th>{t("Mol soni")}</th>
                <th>{t("Ombor kodi")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((v, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{v.ItemCode}</td>
                    <td>{v.ItemName}</td>
                    <td>{v.OnHand}</td>
                    <td>{v.WhsCode}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </WarehouseSytle>
    </Layout>
  );
};

export default Warehouse;
