import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  SuccessModal,
  ErrorModal,
  CreateUserModal,
} from "../../components/Modal";
import ReturnProductStyle from "./ReturnProductStyle";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import api from "../../api";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { ImCancelCircle } from "react-icons/im";

const ReturnProduct = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const productNotCreatedRef = useRef();
  const successRef = useRef();
  const createUserRef = useRef();
  const seriaErrorRef = useRef();

  const location = useLocation();
  const CardCode = get(location, "state.CardCode", 0);
  const DocEntry = get(location, "state.DocEntry", 0);
  const tableData = get(location, "state.tableData", []);

  const getCreateUserRef = useCallback((ref) => {
    createUserRef.current = ref;
  }, []);
  const getProductNotCreatedRef = useCallback((ref) => {
    productNotCreatedRef.current = ref;
  }, []);
  const getSuccessRef = useCallback((ref) => {
    successRef.current = ref;
  }, []);
  const getSeriaErrorRef = useCallback((ref) => {
    seriaErrorRef.current = ref;
  }, []);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const [serialNumbersData, setSerialNumbersData] = useState([]);
  const [newRadarData, setNewRadarData] = useState(tableData);

  //new states
  const [customer, setCustomer] = useState("");
  const [twentySeries, setTwentySeries] = useState(1);
  const [serialNumberInput, setSerialNumberInput] = useState("");
  const [userSerialNumber, setUserSerialNumber] = useState("");
  const [date1, setDate1] = useState(formattedDate);
  const [bonusPrice, setBonusPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [isProductSerialCard2, setIsProductSerialCard2] = useState("");
  const [productBottomName, setProductBottomName] = useState(t("Нет"));
  const [comments, setComments] = useState("");
  const [isCurrentToPayment, setIsCurrentToPayment] = useState(false);

  //new states

  useEffect(() => {
    getInformationOfItem();
    clearTableData();
  }, []);

  const clearTableData = () => {
    let someData = tableData.map((v, i) => {
      return {
        ItemDescription: v.ItemDescription,
        Quantity: 0,
        Price: v.Price,
        U_Cashback: 0,
        LineTotal: 0,
        index: Math.floor(Math.random() * 1000),
        ItemCode: v.ItemCode,
        One_cashback: v.U_Cashback / v.Quantity,
        baseIndex: i,
        DocEntry: v.DocEntry,
      };
    });

    setNewRadarData(someData);
  };

  const getInformationOfItem = () => {
    api
      .get(
        `Invoices(${DocEntry})?$select=DocTotal,CardName,CardCode,DocDate,DocDueDate,DocEntry,DocNum,PaidToDate,DocumentLines,SalesPersonCode,U_SeriyaNomer,U_CloseDate,Comments,U_Vitrina`
      )
      .then((res) => {
        const resData = JSON.parse(res.data);
        setCustomer(get(resData, "CardName", ""));
        setComments(get(resData, "Comments", ""));
      })
      .catch((err) => {
        console.log("err getInformationOfItem", err);
      });
  };

  const changeNumberProduct = (rr) => {
    let aaa = [...newRadarData];
    const serialnumbersArr = rr.filter((v) => {
      return v.index === userSerialNumber;
    });

    for (let i = 0; i < aaa.length; i++) {
      if (aaa[i].index === Number(userSerialNumber)) {
        aaa[i].Quantity = serialnumbersArr.length;
        aaa[i].LineTotal = Number(serialnumbersArr.length) * aaa[i].Price;
        aaa[i].U_Cashback = serialnumbersArr.length * aaa[i].One_cashback;
      }
    }
    setNewRadarData(aaa);
    getBonusAndTotalMoney(aaa);
  };

  const getBonusAndTotalMoney = (data) => {
    let sumB = 0;
    let sumT = 0;
    for (let i = 0; i < data.length; i++) {
      sumB = sumB + data[i].Quantity * data[i].One_cashback;
      sumT = sumT + data[i].LineTotal + data[i].Quantity * data[i].One_cashback;
    }
    setBonusPrice(Number(sumB));
    setPrice(Number(sumT));
  };

  const addSeriaToData = (title) => {
    const oldDatas = [...serialNumbersData];
    setSerialNumberInput(title);
    if (Number(twentySeries) === 1) {
      setSerialNumbersData((pr) => [...pr, { title, index: userSerialNumber }]);
      setSerialNumberInput("");
      changeNumberProduct([...oldDatas, { title, index: userSerialNumber }]);
    } else {
      if (Number(title.slice(-4)) <= 99980) {
        oldDatas.push({ title, index: userSerialNumber });
        for (let i = 1; i < 20; i++) {
          // console.log(String(Number(title.slice(-4)) + i).length);
          if (String(Number(title.slice(-4)) + i).length === 1) {
            oldDatas.push({
              title: title.slice(0, -4) + "000" + (Number(title.slice(-4)) + i),
              index: userSerialNumber,
            });
          } else if (String(Number(title.slice(-4)) + i).length === 2) {
            oldDatas.push({
              title: title.slice(0, -4) + "00" + (Number(title.slice(-4)) + i),
              index: userSerialNumber,
            });
          } else if (String(Number(title.slice(-4)) + i).length === 3) {
            oldDatas.push({
              title: title.slice(0, -4) + "0" + (Number(title.slice(-4)) + i),
              index: userSerialNumber,
            });
          } else {
            oldDatas.push({
              title: title.slice(0, -4) + (Number(title.slice(-4)) + i),
              index: userSerialNumber,
            });
          }
        }
        setSerialNumbersData(oldDatas);
        setSerialNumberInput("");
      } else {
        seriaErrorRef.current?.open();
      }
      changeNumberProduct(oldDatas);
    }
  };

  const delSeria = (indexS) => {
    let aaa = [...newRadarData];
    let ccc = [...serialNumbersData];

    for (let i = 0; i < aaa.length; i++) {
      if (aaa[i].index === indexS) {
        aaa[i].Quantity = 0;
        aaa[i].LineTotal = 0;
      }
    }

    let somesData = ccc.filter((v) => {
      return indexS !== Number(v.index);
    });

    setNewRadarData(aaa);
    setSerialNumbersData(somesData);
    getBonusAndTotalMoney(aaa);
  };

  const delSeriaOwn = (indexS, i) => {
    let aaa = [...newRadarData];
    let ccc = [...serialNumbersData];

    for (let i = 0; i < aaa.length; i++) {
      if (indexS === Number(aaa[i].index)) {
        aaa[i].amount = aaa[i].amount - 1;
        aaa[i].totalCost = aaa[i].totalCost - (aaa[i].bonus + aaa[i].onePrice);

        setBonusPrice((pr) => pr - aaa[i].bonus);
        setPrice((pr) => pr - (aaa[i].bonus + aaa[i].onePrice));
      }
    }

    ccc.splice(i, 1);
    setSerialNumbersData(ccc);
    setNewRadarData(aaa);
  };

  const createOrder = () => {
    setIsMainLoading(true);

    const filteredData = newRadarData.filter((v) => {
      return Number(v.Quantity) > 0;
    });

    const DocumentL = filteredData.map((v, i) => {
      if (Number(v.Quantity) > 0) {
        return {
          ItemCode: v.ItemCode,
          Quantity: v.Quantity,
          LineTotal: Number(v.LineTotal),
          U_Cashback: v.U_Cashback,
          BaseType: 13,
          BaseEntry: DocEntry,
          BaseLine: v.baseIndex,
        };
      }
    });

    const seralText = serialNumbersData.map((v) => {
      return v.title;
    });

    const seriaCurrentText = seralText.toString().replaceAll(",", `\r`);

    api
      .post(
        `$batch`,
        `
--batch_36522ad7-fc75-4b56-8c71-56071383e77c

Content-Type: multipart/mixed;boundary=changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http
Content-Transfer-Encoding: binary
Content-ID: 1

POST /b1s/v2/CreditNotes

  ${JSON.stringify({
    CardCode: CardCode,
    DocDate: date1,
    U_SeriyaNomer: seriaCurrentText,
    Comments: comments,
    DocumentLines: DocumentL,
    DocumentAdditionalExpenses: [
      {
        ExpenseCode: 1,
        LineTotal: bonusPrice,
      },
    ],
  })}


--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http
Content-Transfer-Encoding: binary
Content-ID: 2

PATCH /b1s/v2/Invoices(${DocEntry})

{
"U_CloseDate": "${date1}"
}

--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd--
--batch_36522ad7-fc75-4b56-8c71-56071383e77c--`,
        {
          headers: {
            "Content-Type":
              "multipart/mixed;boundary=batch_36522ad7-fc75-4b56-8c71-56071383e77c",
          },
        }
      )
      .then((res) => {
        const resData = get(res, "data", {});
        if (resData.includes("error")) {
          let message1 = resData.split(`"message" :`)[1];
          let message2 = message1.split(`}`)[0];
          productNotCreatedRef.current?.open(message2);
        } else {
          successRef.current?.open();
          setIsCurrentToPayment(true);
        }
        setIsMainLoading(false);
      })
      .catch((err) => {
        console.log("err createOrder", JSON.parse(err.response.data));
        setIsMainLoading(false);
        productNotCreatedRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "Заказ не создан")
        );
      });
  };

  return (
    <ReturnProductStyle>
      <Layout>
        <div className='containerBig'>
          <div className='tableContainer'>
            <div className='left'>
              <div className='betweenCard4'>
                <div className='userSearchCard'>
                  <div>
                    <p className='topTitle'>{t("Клиент")}</p>
                    <input
                      type='text'
                      className='input'
                      disabled={true}
                      value={customer}
                    />
                  </div>

                  <button
                    className='createUserBtn'
                    onClick={() => createUserRef.current?.open()}
                  >
                    +
                  </button>
                </div>

                <div className='relative'>
                  <p className='topTitle'>{t("Дата")}</p>

                  <input
                    type='date'
                    className='input'
                    defaultValue={date1}
                    onChange={(v) => setDate1(v.target.value)}
                  />
                </div>

                <div className='userSearchCard2'>
                  <div className='mini'>
                    <p className='topTitle'>{t("Бонус")}</p>
                    <input
                      type='number'
                      className='input'
                      value={bonusPrice}
                      disabled={true}
                    />
                  </div>
                  <div className='mini'>
                    <p className='topTitle'>{t("Цена")}</p>
                    <input
                      type='number'
                      className='input'
                      value={price}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className='betweenCard4'>
                <div>
                  <p className='topTitle'>{t("Izoh")}</p>

                  <textarea
                    name='comments'
                    id='comments'
                    cols='50'
                    rows='5'
                    defaultValue={comments}
                    onChange={(v) => setComments(v.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className='right'>
              <div className='betweenCard3'>
                <div className='halfCard'>
                  <div className='between'>
                    <button
                      className={twentySeries === 1 ? "activeT" : "notActiveT"}
                      onClick={() => {
                        setTwentySeries(1);
                      }}
                    >
                      1 ta seria
                    </button>
                    <button
                      className={twentySeries === 20 ? "activeT" : "notActiveT"}
                      onClick={() => {
                        setTwentySeries(20);
                      }}
                    >
                      20 ta seria
                    </button>
                  </div>

                  <div>
                    <p className='topTitle'>
                      {t("Tovar")}: {productBottomName}
                    </p>

                    <button
                      onClick={() => setIsProductSerialCard2(true)}
                      className='input2'
                      disabled={newRadarData.length > 0 ? false : true}
                    >
                      {t("Tovar")}
                    </button>

                    {isProductSerialCard2 ? (
                      <div className='productSerialCard'>
                        {newRadarData.map((v, i) => {
                          return (
                            <p
                              className='seriaTitle'
                              key={i}
                              onClick={() => {
                                setUserSerialNumber(v.index);
                                setIsProductSerialCard2(false);
                                setProductBottomName(v.ItemDescription);
                              }}
                            >
                              {v.ItemDescription}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <p className='topTitle'>{t("Skaner")}</p>
                    <input
                      type='text'
                      name='serialNumber'
                      id='serialNumber'
                      className='inputSeria'
                      value={serialNumberInput}
                      onInput={(v) => {
                        setSerialNumberInput(v.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addSeriaToData(serialNumberInput);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='halfCard2'>
                  <p className='serialNumberTitle'>{t("Seria raqamlar")}</p>
                  <div className='serialMainCard'>
                    {serialNumbersData.map((v, i) => {
                      return (
                        <div key={i} className='seriaCardOwn'>
                          <p>{v.title}</p>
                          <button onClick={() => delSeriaOwn(v.index, i)}>
                            <ImCancelCircle />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className='tableContainerMini'>
              <p className='listTitle'>{t("Jadval")}</p>
              <table>
                <thead>
                  <tr>
                    <th>N</th>
                    <th>{t("Nomi")}</th>
                    <th>{t("Soni")}</th>
                    <th>{t("Dona narxi")}</th>

                    <th>{t("Bonus")}</th>
                    <th>{t("Umumiy bonus")}</th>
                    <th>{t("Bonussiz umumiy narxi")}</th>
                    <th>{t("Seria")}</th>
                  </tr>
                </thead>
                <tbody>
                  {newRadarData.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{v.ItemDescription}</td>
                        <td>{v.Quantity}</td>
                        <td>{v.Price}</td>
                        <td>{v.One_cashback}</td>
                        <td>{v.U_Cashback}</td>
                        <td>{v.LineTotal}</td>
                        <td>
                          <button
                            className='del'
                            onClick={() => delSeria(v.index)}
                          >
                            {t("Ochirish")}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className='bottomCardMain'>
            <div className='bottomCard'>
              {!isCurrentToPayment ? (
                <Button isLoading={isMainLoading} onClick={createOrder}>
                  {t("Создать")}
                </Button>
              ) : null}

              <Button
                onClick={() =>
                  navigate("/viewItem", {
                    state: {
                      CardCode,
                      DocEntry,
                    },
                  })
                }
              >
                {t("Назад")}
              </Button>
            </div>
          </div>
        </div>
      </Layout>

      <SuccessModal getRef={getSuccessRef} title={"Qaytarish yaratilindi"} />
      <ErrorModal
        getRef={getProductNotCreatedRef}
        title={t("Buyurtma yaratilmadi")}
      />
      <ErrorModal getRef={getSeriaErrorRef} title={t("Xatolik yuz berdi")} />
      <CreateUserModal getRef={getCreateUserRef} />
    </ReturnProductStyle>
  );
};

export default ReturnProduct;
