import styled from 'styled-components';
import colors from '../../assets/style/colors';

const CreateProductsStyle = styled.div`
  .containerBig {
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    align-items: flex-start;

    .left {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      /* justify-content: space-between; */
    }

    .betweenCard3 {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      margin-top: 20px;
      .halfCard2 {
        margin-left: 50px;
      }
      .serialNumberTitle {
        margin: 0;
        font-weight: bold;
      }

      .serialMainCard {
        width: 230px;
        background-color: #e0edff;
        height: 150px;
        padding: 10px;
        overflow: auto;
        p {
          margin: 0;
        }
      }

      .alignCenter {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .radoiInput[type='radio'] {
          appearance: none;
          padding: 10px;
          background-color: #d6d6d6;
          border-radius: 50%;
        }
        .radoiInput[type='radio']:checked {
          background-color: #0cae00;
        }
        .labelNumberOfProduct {
          margin-left: 5px;
        }
      }
      .input {
        border: 2px solid ${colors.blue2};
        font-size: 15px;
        padding: 5px 10px;
        border-radius: 5px;
        color: ${colors.blue1};
        width: 150px;
        outline: none;
        /* margin: 0 0 10px 10px; */
      }
    }

    .right {
      width: 55%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      /* background-color: #0cae00; */

      .betweenCard2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
      }
      .mainCard2 {
        display: flex;
        justify-content: space-between;
        /* background-color: #ededed; */
        border-radius: 5px;
        width: 100%;
        height: 70px;
      }
      .input {
        border: 2px solid ${colors.blue2};
        font-size: 15px;
        padding: 5px 10px;
        border-radius: 5px;
        color: ${colors.blue1};
        width: 100px;
        outline: none;
      }
      .input2 {
        border: 2px solid ${colors.blue2};
        font-size: 15px;
        padding: 5px 10px;
        border-radius: 5px;
        color: ${colors.blue1};
        width: 220px;
        outline: none;
      }
      .btnAdd {
        width: 50px !important;
        height: 32px;
        background-color: #0cae00;
        border: none;
        border-radius: 5px;
        font-size: 18px;
        color: #ffffff;
        margin-top: 8px;
      }
    }
    .bottomCard {
      display: flex;
      justify-content: space-between;
      width: 30%;
      .cancelBtn {
        background-color: red;
        margin: 50px;
        padding: 100px;
      }
      margin-top: 30px;
    }
    .tableContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      table {
        border-collapse: collapse;
        width: 100%;
        th,
        td {
          border: 1px solid gray;
          text-align: center;
        }
      }
    }
  }
  .topTitle {
    margin: 5px 0 2px 0;
    font-size: 14px;
  }
  .tableContainerMini {
    width: 100%;
  }
  .listTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 5px 0 5px 0;
    text-align: center;
  }
  .del {
    background-color: red;
    border: none;
    padding: 4px 10px;
    color: #ffffff;
    border-radius: 4px;
  }
  .inputSeria {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 200px;
    outline: none;
  }
  .betweenCard4 {
    width: 100%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    /* justify-content: space-between; */
    .userSearchCard {
      display: flex;
      align-items: flex-end;
      /* justify-content: space-between; */
      width: 50%;
      .createUserBtn {
        margin: 0 5px;
        border-radius: 5px;
        background: rgb(2, 67, 185);
        background: linear-gradient(
          90deg,
          rgba(2, 67, 185, 1) 0%,
          rgba(49, 151, 227, 1) 100%
        );
        font-size: 15px;
        color: #ffffff;
        border: none;
        width: 40px;
        height: 30px;
      }
      .input {
        border: 2px solid ${colors.blue2};
        font-size: 15px;
        padding: 5px 10px;
        border-radius: 5px;
        color: ${colors.blue1};
        width: 220px;
        outline: none;
        /* margin-bottom: 50px; */
      }
    }
    .relative {
      width: 50%;
    }
    .userSearchCard2 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .mini {
        width: 50%;
        .input {
          border: 2px solid ${colors.blue2};
          font-size: 17px;
          padding: 3px 10px;
          border-radius: 5px;
          color: ${colors.blue1};
          width: 220px;
          outline: none;
          background-color: #e6ebff;
          font-weight: bold;
        }
      }
    }
    .inputDefault {
      border: 2px solid ${colors.blue2};
      font-size: 15px;
      padding: 10px;
      border-radius: 5px;
      color: ${colors.blue1};
      width: 90%;
      margin-bottom: 50px;
      background-color: #f5f9ff;
    }
    .input {
      border: 2px solid ${colors.blue2};
      font-size: 15px;
      padding: 5px 10px;
      border-radius: 5px;
      color: ${colors.blue1};
      width: 220px;
      outline: none;
      /* margin-bottom: 50px; */
    }
  }
  .bottomCardMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
  }
  .paymentBtn {
    margin: 0 5px;
    border-radius: 5px;
    background: rgb(2, 67, 185);
    background: linear-gradient(
      90deg,
      rgba(2, 67, 185, 1) 0%,
      rgba(49, 151, 227, 1) 100%
    );
    font-size: 15px;
    color: #ffffff;
    border: none;
    /* width: 40px; */
    padding: 10px 20px;
  }
  .none {
    display: none;
  }

  .textarea {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    outline: none;
  }
  .leftCard {
    position: relative;

    .productSerialCard {
      z-index: 50;
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 0 10px;
      background-color: #e0edff;
      height: 300px;
      width: 170px;
      overflow: auto;
      .seriaTitle {
        cursor: pointer;
      }
      .seriaTitle:hover {
        background-color: #ffffff;
      }
    }
  }
  .halfCard {
    position: relative;

    .productSerialCard {
      z-index: 50;
      min-width: 200px;
      position: absolute;
      top: 80px;
      left: 0;
      padding: 0 10px;
      background-color: #e0edff;
      .seriaTitle {
        cursor: pointer;
      }
      .seriaTitle:hover {
        background-color: #ffffff;
      }
    }
  }

  .activeT {
    padding: 5px 10px;
    border: none;
    background-color: #2f5df4;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
  }
  .notActiveT {
    padding: 5px 10px;
    border: none;
    background-color: #e2f4ff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
  }
  .between {
    display: flex;
    justify-content: space-between;
  }
  .seriaCardOwn {
    display: flex;
    justify-content: space-between;
    button {
      background-color: red;
      border: none;
      margin-bottom: 1px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .seriaCardOwn:hover {
    background-color: #ffffff;
  }
  .cancelBtn2 {
    border: none;
    background-color: red;
    color: #ffffff;
    padding: 3px 4px 0 4px;
    margin: 0;
    border-radius: 10px;
  }
  .center {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    right: 0;
  }
  textarea {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 520px;
    outline: none;
  }
  .relative2 {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .userSearchCard2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .mini {
      width: 50%;
      .input {
        border: 2px solid ${colors.blue2};
        font-size: 17px;
        padding: 3px 10px;
        border-radius: 5px;
        color: ${colors.blue1};
        width: 220px;
        outline: none;
        background-color: #e6ebff;
        font-weight: bold;
      }
    }
  }
  .inputDefault {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 90%;
    margin-bottom: 50px;
    background-color: #f5f9ff;
  }
  .inputDate {
    border: 2px solid ${colors.blue2};
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${colors.blue1};
    width: 120px;
    outline: none;
  }
  .btnBack {
    position: absolute;
    top: 5px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    padding: 5px 20px;
  }
`;
export default CreateProductsStyle;
