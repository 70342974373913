import React from 'react';
import LayoutStyle from './LayoutStyle';

const LayoutView = ({children}) => {
  return (
    <LayoutStyle>
      <div className="container">{children}</div>
    </LayoutStyle>
  );
};

export default LayoutView;
