import styled from "styled-components";

const LaunchStyle = styled.div`
  .container {
    flex: 1;
    display: flex;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    height: 90vh;

    .text {
      background: linear-gradient(
        90deg,
        rgba(18, 77, 192, 1) 0%,
        rgba(44, 121, 207, 1) 35%,
        rgba(128, 234, 255, 1) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 150px;
      font-weight: bold;
      animation-name: launchAnimation;
      animation-duration: 2s;
      background-position: center center;
    }
  }
  @keyframes launchAnimation {
    from {
      transform: translateY(50px);
      scale: 0.9;
    }
    to {
      transform: translateY(0);
      scale: 1;
    }
  }
`;
export default LaunchStyle;
