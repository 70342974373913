import styled from "styled-components";
import colors from "../../assets/style/colors";
import backImage from "../../assets/images/back.jpg";

const LoginStyle = styled.div`
  .mainContainer {
    height: 100vh;
    padding: 20px;
    background-size: 150%;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${backImage});
    transition: 1s;
    animation-name: animationForBack;
    animation-duration: 50s;
    animation-iteration-count: infinite;

    .text {
      color: green;
    }
    .logoTitle {
      color: rgba(44, 121, 207, 1);
      font-size: 50px;
      text-align: center;
    }
    .container {
      height: 70%;
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 10px;
      background-color: #fffffff2;
      .img {
        width: 150px;
        height: 150px;
        margin-top: 10px;
      }
      .card {
        width: 90%;
        padding-left: 50px;
        margin-bottom: 50px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        .label {
          font-size: 20px;
          color: ${colors.blue1};
          width: 100px;
        }
        .labelCard{
          width: 90%;
          display: flex;
          justify-content: flex-start;
        }
        .input {
          border: 2px solid ${colors.blue2};
          font-size: 20px;
          padding: 10px;
          border-radius: 5px;
          color: ${colors.blue1};
          margin-top: 10px;
          width: 350px;
          outline: none;
        }
      }
      .btn {
        padding: 10px 30px;
        border-radius: 5px;
        background-color: ${colors.mainColor};
        color: #ffffff;
        font-size: 18px;
        border: none;
      }
    }
    .passwordCard {
      border: 2px solid ${colors.blue2};
      border-radius: 5px;
      margin-top: 10px;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      .input2 {
        width: 80%;
        font-size: 20px;
        padding: 10px 0 10px 10px;
        color: ${colors.blue1};
        border: none;
        outline: none;
        border-radius: 5px;
      }
      .eyeBtn {
        background-color: #ffffff;
        border: none;
        width: 10%;
        font-size: 18px;
        padding-top: 4px;
      }
    }
    .btnCardBottom {
      width: 90%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
  @keyframes animationForBack {
    0% {
      background-position: bottom left;
    }
    50% {
      background-position: bottom right;
    }
    100% {
      background-position: bottom left;
    }
  }
`;
export default LoginStyle;
