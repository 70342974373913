import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Convertation,
  Login,
  Launch,
  ViewItem,
  AllProducts,
  CreateProducts,
  Sales,
  Debts,
  GiveBonus,
  TodaysSale,
  Warehouse,
  ViewSales,
  DollarCourse,
  ReturnProduct,
} from "../screens";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Launch />} />
        <Route path='/convertation' element={<Convertation />} />
        <Route path='/login' element={<Login />} />
        <Route path='/viewItem' element={<ViewItem />} />
        <Route path='/viewSales' element={<ViewSales />} />
        <Route path='/allProducts' element={<AllProducts />} />
        <Route path='/createProducts' element={<CreateProducts />} />
        <Route path='/sales' element={<Sales />} />
        <Route path='/debts' element={<Debts />} />
        <Route path='/giveBonus' element={<GiveBonus />} />
        <Route path='/todaysSale' element={<TodaysSale />} />
        <Route path='/warehouse' element={<Warehouse />} />
        <Route path='/dollarCourse' element={<DollarCourse />} />
        <Route path='/returnProduct' element={<ReturnProduct />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
