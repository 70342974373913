import React, { memo, useEffect, useState } from "react";
import ErrorModalStyle from "./ErrorModalStyle";
import ErrorImage from "../../../assets/images/error.png";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ErrorModal = ({ title = "", getRef }) => {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [titleS, setTitleS] = useState(title);
  useEffect(() => {
    const ref = {
      open: (soz) => {
        setIsOpenModal(true);
        setTitleS(soz);
      },
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel='Example Modal'
      ariaHideApp={false}
    >
      <ErrorModalStyle>
        <div className='card'>
          <img src={ErrorImage} alt='error image' className='img' />
          <h1>{t("Ошибка")}</h1>
          <p className='title'>{titleS}</p>
          <div className='centerCard'>
            <button className='btnN' onClick={() => setIsOpenModal(false)}>
              {t("Понятно")}
            </button>
          </div>
        </div>
      </ErrorModalStyle>
    </Modal>
  );
};

export default memo(ErrorModal);
