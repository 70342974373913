import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  SuccessModal,
  ErrorModal,
  PaymentModal,
  CreateUserModal,
  WarningModal,
} from '../../components/Modal';
import CreateProductsStyle from './CreateProductsStyle';
import {useNavigate} from 'react-router-dom';
import Button from '../../components/Button';
import api from '../../api';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ImCancelCircle} from 'react-icons/im';
import LayoutView from '../../components/LayoutView';
import {IoArrowBack} from 'react-icons/io5';

const CreateProducts = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {getMe} = useSelector(state => state.main);

  const productNotCreatedRef = useRef();
  const successRef = useRef();
  const notEnoughInfoRef = useRef();
  const paymentModalRef = useRef();
  const createUserRef = useRef();
  const seriaErrorRef = useRef();
  const warningRef = useRef();

  const getCreateUserRef = useCallback(ref => {
    createUserRef.current = ref;
  }, []);
  const getProductNotCreatedRef = useCallback(ref => {
    productNotCreatedRef.current = ref;
  }, []);
  const getSuccessRef = useCallback(ref => {
    successRef.current = ref;
  }, []);
  const getNotEnoughInfoRef = useCallback(ref => {
    notEnoughInfoRef.current = ref;
  }, []);
  const getPaymentModalRef = useCallback(ref => {
    paymentModalRef.current = ref;
  }, []);
  const getSeriaErrorRef = useCallback(ref => {
    seriaErrorRef.current = ref;
  }, []);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and pad with '0' if needed
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const [customerData, setCustomerData] = useState([]);
  const [productNameData, setProductNameData] = useState([]);
  const [serialNumbersData, setSerialNumbersData] = useState([]);
  const [newRadarData, setNewRadarData] = useState([]);

  const [customer, setCustomer] = useState('');
  const [productName, setProductName] = useState('');
  const [twentySeries, setTwentySeries] = useState(1);
  const [serialNumberInput, setSerialNumberInput] = useState('');
  const [onePriceInput, setOnePriceInput] = useState('');
  const [bonusInput, setBonusInput] = useState('');
  const [userSerialNumber, setUserSerialNumber] = useState('');
  const [date1, setDate1] = useState(formattedDate);
  const [date2, setDate2] = useState(formattedDate);
  const [userCardCode, setUserCardCode] = useState('');
  const [productCardCode, setProductCardCode] = useState('');
  const [docEntry, setDocEntry] = useState('');
  const [isCurrentToPayment, setIsCurrentToPayment] = useState(false);
  const [bonusPrice, setBonusPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [isMainLoading2, setIsMainLoading2] = useState(false);
  const [isProductSerialCard, setIsProductSerialCard] = useState('');
  const [isProductSerialCard2, setIsProductSerialCard2] = useState('');
  const [productBottomName, setProductBottomName] = useState(t('Нет'));
  const [userGroup, setuserGroup] = useState('');
  const [comments, setComments] = useState('');
  const [vitrina, setVitrina] = useState(false);
  const [isCreated, setIsCreated] = useState(true);

  console.log(isCreated);

  useEffect(() => {
    searchProduct('');
  }, []);

  const searchUser = (a = '') => {
    setCustomer(a);
    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO' and (contains(CardName, '${a}') or contains(Phone1, '${a}'))`,
      )
      .then(res => {
        const resData = get(JSON.parse(res.data), 'value', []);
        setCustomerData(resData);
        if (a.includes(' , ')) {
          setUserCardCode(a.split(' , ')[1]);
          setuserGroup(a.split(' , ')[2]);
        }
      })
      .catch(err => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate('/login');
        } else {
          console.log('err searchUser:', err);
        }
      });
  };

  const searchProduct = (a = '') => {
    api
      .get(
        `Items?$select=U_Bonus,ItemPrices,ItemCode,ItemName,QuantityOnStock&$filter=contains(ItemName,'${a}') and QuantityOnStock gt 0`,
        {
          headers: {
            Prefer: 'odata.maxpagesize=100',
          },
        },
      )
      .then(res => {
        const resData = get(JSON.parse(res.data), 'value', []);
        // console.log("resData searchProduct", resData);
        setProductNameData(resData);
      })
      .catch(err => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate('/login');
        } else {
          console.log('err searchProduct:', err);
        }
      });
  };

  // const cancelRadarData = i => {
  //   const oldData = [...newRadarData];
  //   oldData.splice(i, 1);
  //   setNewRadarData(oldData);
  // };

  const changeNumberProduct = rr => {
    let aaa = [...newRadarData];
    const serialnumbersArr = rr.filter(v => {
      return v.index === userSerialNumber;
    });

    for (let i = 0; i < aaa.length; i++) {
      if (aaa[i].index === Number(userSerialNumber)) {
        aaa[i].amount = serialnumbersArr.length;
        aaa[i].totalCost =
          Number(serialnumbersArr.length) * (aaa[i].onePrice + aaa[i].bonus);
      }
    }
    setNewRadarData(aaa);
    getBonusAndTotalMoney(aaa);
  };

  const getBonusAndTotalMoney = data => {
    let sumB = 0;
    let sumT = 0;
    for (let i = 0; i < data.length; i++) {
      sumB = sumB + data[i].amount * data[i].bonus;
      sumT = sumT + data[i].totalCost;
    }
    setBonusPrice(sumB);
    setPrice(sumT);
  };

  const addSeriaToData = title => {
    const oldDatas = [...serialNumbersData];
    setSerialNumberInput(title);

    if (Number(twentySeries) === 1) {
      setSerialNumbersData(pr => [...pr, {title, index: userSerialNumber}]);
      setSerialNumberInput('');
      changeNumberProduct([...oldDatas, {title, index: userSerialNumber}]);
    } else {
      oldDatas.push({title, index: userSerialNumber});
      for (let i = 1; i < 20; i++) {
        if (String(Number(title.slice(-4)) + i).length === 1) {
          oldDatas.push({
            title: title.slice(0, -4) + '000' + (Number(title.slice(-4)) + i),
            index: userSerialNumber,
          });
        } else if (String(Number(title.slice(-4)) + i).length === 2) {
          oldDatas.push({
            title: title.slice(0, -4) + '00' + (Number(title.slice(-4)) + i),
            index: userSerialNumber,
          });
        } else if (String(Number(title.slice(-4)) + i).length === 3) {
          oldDatas.push({
            title: title.slice(0, -4) + '0' + (Number(title.slice(-4)) + i),
            index: userSerialNumber,
          });
        } else {
          oldDatas.push({
            title: title.slice(0, -4) + (Number(title.slice(-4)) + i),
            index: userSerialNumber,
          });
        }
      }
      setSerialNumbersData(oldDatas);
      setSerialNumberInput('');
      changeNumberProduct(oldDatas);
    }
  };

  const addRadarData = () => {
    setNewRadarData(pr => [
      ...pr,
      {
        nameProduct: productName,
        onePrice: Number(onePriceInput),
        amount: 0,
        totalCost: 0,
        bonus: Number(bonusInput),
        index: Math.floor(Math.random() * 1000),
        cardCode: productCardCode,
      },
    ]);

    setProductName('');
    setOnePriceInput('');
    setBonusInput('');
  };

  const delSeria = indexS => {
    let aaa = [...newRadarData];
    let ccc = [...serialNumbersData];

    for (let i = 0; i < aaa.length; i++) {
      if (aaa[i].index === indexS) {
        aaa[i].amount = 0;
        aaa[i].totalCost = 0;
      }
    }

    let somesData = ccc.filter(v => {
      return indexS !== Number(v.index);
    });

    setNewRadarData(aaa);
    setSerialNumbersData(somesData);
    getBonusAndTotalMoney(aaa);
  };

  const delSeriaOwn = (indexS, i) => {
    let aaa = [...newRadarData];
    let ccc = [...serialNumbersData];

    for (let i = 0; i < aaa.length; i++) {
      if (indexS === Number(aaa[i].index)) {
        aaa[i].amount = aaa[i].amount - 1;
        aaa[i].totalCost = aaa[i].totalCost - (aaa[i].bonus + aaa[i].onePrice);

        setBonusPrice(pr => pr - aaa[i].bonus);
        setPrice(pr => pr - (aaa[i].bonus + aaa[i].onePrice));
      }
    }

    ccc.splice(i, 1);
    setSerialNumbersData(ccc);
    setNewRadarData(aaa);
  };

  const delTovar = (i, indexS) => {
    let aaa = [...newRadarData];
    let ccc = [...serialNumbersData];

    let somesData = ccc.filter(v => {
      return indexS !== Number(v.index);
    });

    aaa.splice(i, 1);
    setNewRadarData(aaa);
    setSerialNumbersData(somesData);
    getBonusAndTotalMoney(aaa);
  };

  const getProductInfoToState = (pName, price, bonuss, iCode) => {
    setProductName(pName);
    setOnePriceInput(Number(price));
    if (bonuss == 'null') {
      setBonusInput(0);
    } else {
      setBonusInput(Number(bonuss));
    }
    setProductCardCode(iCode);
    setIsProductSerialCard(false);
  };

  const createOrder = () => {
    setIsMainLoading(true);

    const DocumentL = newRadarData.map(v => {
      return {
        ItemCode: v.cardCode, // Mol kodi
        Quantity: v.amount,
        LineTotal: v.onePrice * v.amount, // dona narxi
        U_Cashback: v.bonus * v.amount, // shu mollarga umumiy bonus
        WarehouseCode: getMe['U_Sklad'],
      };
    });

    const seralText = serialNumbersData.map(v => {
      return v.title.toUpperCase();
    });
    const seriaCurrentText = seralText.toString().replaceAll(',', `\n`);

    const umumiySum = newRadarData.map(v => {
      return v.amount * v.bonus;
    });

    const sum = umumiySum.reduce((partialSum, a) => partialSum + a, 0);

    // const numberOfAmunts = newRadarData.filter(v => {
    //   return v.amount > 0;
    // });

    api
      .post(`Invoices`, {
        CardCode: userCardCode,
        DocDate: date1,
        DocDueDate: date2,
        SalesPersonCode: getMe['SalesPersonCode'],
        U_Vitrina: vitrina ? 'Vitrina' : null,
        U_SeriyaNomer: seriaCurrentText,
        Comments: comments,
        DocumentLines: DocumentL,
        DocumentAdditionalExpenses: [
          {
            ExpenseCode: 1,
            LineTotal: sum,
          },
        ],
      })
      .then(res => {
        const docE = get(JSON.parse(res.data), 'DocEntry', 0);
        setDocEntry(docE);
        setIsMainLoading(false);
        setIsCurrentToPayment(true);
        setIsCreated(false);
        successRef.current?.open();
      })
      .catch(err => {
        setIsMainLoading(false);
        productNotCreatedRef.current?.open(
          get(
            JSON.parse(err.response.data),
            'error.message',
            'Заказ не создан',
          ),
        );
      });
  };

  const createPayment = (sum, typeOfPayment, valuta, dollarCourse, dateC) => {
    setIsMainLoading2(true);
    api
      .post(
        `$batch`,
        `
--batch_36522ad7-fc75-4b56-8c71-56071383e77c

Content-Type: multipart/mixed;boundary=changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http 
Content-Transfer-Encoding: binary 
Content-ID: 1 


POST /b1s/v2/IncomingPayments

  ${JSON.stringify({
    CardCode: userCardCode,
    CashSum: sum,
    CashAccount:
      valuta === 'UZS' && typeOfPayment === 'U_CashAccount'
        ? getMe['U_CashUzsAccount']
        : getMe[typeOfPayment],
    DocDate: dateC,
    DocCurrency: valuta,
    BankChargeAmount: 0,
    U_Bonus: typeOfPayment === 'U_BonusAccount' ? 'Bonus berildi' : null,
    U_SlpCode: getMe['SalesPersonCode'], // Do'kon kodi. Employee->SalesPersonCode
    PaymentInvoices: [
      {
        DocEntry: docEntry,
        SumApplied: valuta === 'UZS' ? Number(sum) / dollarCourse : Number(sum),
      },
    ],
  })}


--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd
Content-Type: application/http 
Content-Transfer-Encoding: binary 
Content-ID: 3 

PATCH /b1s/v2/Invoices(${docEntry})

  ${JSON.stringify({
    U_CloseDate: `${dateC}`,
  })}


--changeset_77162fcd-b8da-41ac-a9f8-9357efbbd--
--batch_36522ad7-fc75-4b56-8c71-56071383e77c--`,
        {
          headers: {
            'Content-Type':
              'multipart/mixed;boundary=batch_36522ad7-fc75-4b56-8c71-56071383e77c',
          },
        },
      )
      .then(res => {
        const resData = get(res, 'data', '');
        if (resData.includes('error')) {
          let message1 = res.data.split(`"message" : "`)[1];
          let message2 = message1.split(`"`);
          productNotCreatedRef.current?.open(message2[0]);
        } else {
          setIsMainLoading2(false);
          navigate('/viewItem', {
            state: {CardCode: userCardCode, DocEntry: docEntry},
          });
          setIsCurrentToPayment(false);
        }
      })
      .catch(err => {
        setIsMainLoading2(false);
        productNotCreatedRef.current?.open(
          get(
            JSON.parse(err.response.data),
            'error.message',
            'Заказ не создан',
          ),
        );
      });
  };

  const cleanAllInfo = () => {
    setCustomer('');
    setBonusPrice(0);
    setPrice(0);
    setSerialNumbersData([]);
    setNewRadarData([]);
    setIsCurrentToPayment(false);
    setProductBottomName('');
    setVitrina(false);
    setuserGroup('');
  };

  // debounce

  useEffect(() => {
    const delay = 1000;
    let timeoutId;

    if (customer) {
      timeoutId = setTimeout(() => {
        searchUser(customer);
      }, delay);
    }

    return () => {
      // Agar component o'chirilsa, timeoutni bekor qilish
      clearTimeout(timeoutId);
    };
  }, [customer]);

  const handleChange = e => {
    const newSearchTerm = e.target.value;
    setCustomer(newSearchTerm);
  };

  return (
    <CreateProductsStyle>
      <LayoutView>
        <button
          className="btnBack"
          onClick={
            isCreated
              ? () => {
                  warningRef.current?.open();
                }
              : () => {
                  navigate(-1);
                }
          }>
          <IoArrowBack />
        </button>
        <div className="containerBig">
          <div className="tableContainer">
            <div className="left">
              <div className="betweenCard4">
                <div className="userSearchCard">
                  <div>
                    <p className="topTitle">
                      {t('Клиент')}, {t('Guruh')}: <b>{userGroup}</b>
                    </p>
                    <input
                      type="text"
                      list="client"
                      className="input"
                      value={customer}
                      onChange={handleChange}
                    />
                    <datalist id="client">
                      {customerData.map((v, i) => (
                        <option
                          key={i}
                          value={`${get(v, 'CardName', '')} , ${get(
                            v,
                            'CardCode',
                            '',
                          )} , ${get(getMe, `GroupCodes.${v.GroupCode}`, '')}`}
                        />
                      ))}
                    </datalist>
                  </div>

                  <button
                    className="createUserBtn"
                    onClick={() => createUserRef.current?.open()}>
                    +
                  </button>
                </div>

                <div className="relative2">
                  <div>
                    <p className="topTitle">{t('Дата')}</p>

                    <input
                      type="date"
                      className="inputDate"
                      defaultValue={date1}
                      onChange={v => setDate1(v.target.value)}
                    />
                  </div>
                  <div>
                    <p className="topTitle">{t('Bajarilish sanasi')}</p>

                    <input
                      type="date"
                      className="inputDate"
                      defaultValue={date2}
                      onChange={v => setDate2(v.target.value)}
                    />
                  </div>
                </div>

                <div className="userSearchCard2">
                  <div className="mini">
                    <p className="topTitle">{t('Бонус')}</p>
                    <input
                      type="number"
                      className="input"
                      value={bonusPrice}
                      disabled={true}
                    />
                  </div>
                  <div className="mini">
                    <p className="topTitle">{t('Цена')}</p>
                    <input
                      type="number"
                      className="input"
                      value={price}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="betweenCard4">
                <div>
                  <p className="topTitle">{t('Izoh')}</p>

                  <textarea
                    name="comments"
                    id="comments"
                    cols="50"
                    rows="5"
                    onChange={v => setComments(v.target.value)}></textarea>
                </div>
                <div className="vitrinaCard">
                  <p>{t('Vitrina')}</p>
                  <input
                    type="checkbox"
                    className="checkBox"
                    value={vitrina}
                    onChange={() => setVitrina(pr => !pr)}
                  />
                </div>
              </div>
            </div>

            <div className="right">
              <div className="betweenCard2">
                <div className="mainCard2">
                  <div className="leftCard">
                    <p className="topTitle">
                      {t('Название антирадара')} : {productName}
                    </p>
                    <button
                      onClick={() => setIsProductSerialCard(true)}
                      className="input2">
                      {t('Название антирадара')}
                    </button>

                    {isProductSerialCard ? (
                      <div className="productSerialCard">
                        <div className="center">
                          <button
                            className="cancelBtn2"
                            onClick={() => setIsProductSerialCard(false)}>
                            <ImCancelCircle />
                          </button>
                        </div>
                        {productNameData.map((v, i) => {
                          return (
                            <p
                              key={i}
                              className="seriaTitle"
                              onClick={() =>
                                getProductInfoToState(
                                  get(v, 'ItemName', ''),
                                  get(v, 'ItemPrices[0].Price', ''),
                                  get(v, 'U_Bonus', ''),
                                  get(v, 'ItemCode', ''),
                                )
                              }>
                              {get(v, 'ItemName', '')}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <p className="topTitle">{t('Dona narxi')}</p>
                    <input
                      type="number"
                      className="input"
                      value={onePriceInput}
                      onChange={v => setOnePriceInput(v.target.value)}
                    />
                  </div>
                  <div>
                    <p className="topTitle">{t('Bonus')}</p>
                    <input
                      type="number"
                      className="input"
                      value={bonusInput}
                      onChange={v => setBonusInput(v.target.value)}
                    />
                  </div>
                  <div>
                    <p></p>
                    <button
                      className="btnAdd"
                      onClick={() => addRadarData()}
                      disabled={
                        Number(onePriceInput) > 0 || Number(bonusInput) > 0
                          ? false
                          : true
                      }>
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="betweenCard3">
                <div className="halfCard">
                  <div className="between">
                    <button
                      className={twentySeries === 1 ? 'activeT' : 'notActiveT'}
                      onClick={() => {
                        setTwentySeries(1);
                      }}>
                      {t('{{numberSeria}} ta seria', {numberSeria: 1})}
                    </button>
                    <button
                      className={twentySeries === 20 ? 'activeT' : 'notActiveT'}
                      onClick={() => {
                        setTwentySeries(20);
                      }}>
                      {t('{{numberSeria}} ta seria', {numberSeria: 20})}
                    </button>
                  </div>

                  <div>
                    <p className="topTitle">
                      {t('Tovar')}: {productBottomName}
                    </p>

                    <button
                      onClick={() => setIsProductSerialCard2(true)}
                      className="input2"
                      disabled={newRadarData.length > 0 ? false : true}>
                      {t('Tovar')}
                    </button>

                    {isProductSerialCard2 ? (
                      <div className="productSerialCard">
                        {newRadarData.map((v, i) => {
                          return (
                            <p
                              className="seriaTitle"
                              key={i}
                              onClick={() => {
                                setUserSerialNumber(v.index);
                                setIsProductSerialCard2(false);
                                setProductBottomName(v.nameProduct);
                              }}>
                              {v.nameProduct}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <p className="topTitle">{t('Skaner')}</p>
                    <input
                      type="text"
                      name="serialNumber"
                      id="serialNumber"
                      className="inputSeria"
                      value={serialNumberInput}
                      onInput={v => {
                        setSerialNumberInput(v.target.value);
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          addSeriaToData(serialNumberInput);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="halfCard2">
                  <p className="serialNumberTitle">{t('Seria raqamlar')}</p>
                  <div className="serialMainCard">
                    {serialNumbersData.map((v, i) => {
                      return (
                        <div key={i} className="seriaCardOwn">
                          <p>{v.title}</p>
                          <button onClick={() => delSeriaOwn(v.index, i)}>
                            <ImCancelCircle />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="tableContainerMini">
              <p className="listTitle">{t('Jadval')}</p>
              <table>
                <thead>
                  <tr>
                    <th>N</th>
                    <th>{t('Nomi')}</th>
                    <th>{t('Soni')}</th>
                    <th>{t('Dona narxi')}</th>
                    <th>{t('Dona bonus')}</th>
                    <th>{t('Umumiy narxi')}</th>
                    <th>{t('Seria')}</th>
                    <th>{t('Tovar')}</th>
                  </tr>
                </thead>
                <tbody>
                  {newRadarData.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{v.nameProduct}</td>
                        <td>{v.amount}</td>
                        <td>{v.onePrice}</td>
                        <td>{v.bonus}</td>
                        <td>{v.totalCost}</td>
                        <td>
                          <button
                            className="del"
                            onClick={() => delSeria(v.index)}>
                            {t('Ochirish')}
                          </button>
                        </td>
                        <td>
                          <button
                            className="del"
                            onClick={() => delTovar(i, v.index)}>
                            {t('Ochirish')}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="bottomCardMain">
            <div className="bottomCard">
              {!isCurrentToPayment ? (
                <Button isLoading={isMainLoading} onClick={createOrder}>
                  {t('Создать')}
                </Button>
              ) : null}
            </div>
            {isCurrentToPayment ? (
              <div className="bottomCard">
                <Button isLoading={isMainLoading} onClick={cleanAllInfo}>
                  {t('Yangilash')}
                </Button>
                <Button
                  onClick={() => paymentModalRef.current?.open(Number(price))}
                  isLoading={isMainLoading2}>
                  {t('Tolov')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </LayoutView>

      <>
        <PaymentModal
          getRef={getPaymentModalRef}
          onConfirm={(sum, typeOfPayment, valuta, dollarCourse, dateC) =>
            createPayment(sum, typeOfPayment, valuta, dollarCourse, dateC)
          }
          onClose={() => paymentModalRef.current?.close()}
        />
        <SuccessModal
          getRef={getSuccessRef}
          title={t('Заказ успешно создан')}
        />
        <ErrorModal getRef={getProductNotCreatedRef} />
        <ErrorModal
          getRef={getNotEnoughInfoRef}
          title={t('Tolov uchun malumotlarni togri kiriting')}
        />
        <ErrorModal getRef={getSeriaErrorRef} title={t('Xatolik yuz berdi')} />
        <CreateUserModal getRef={getCreateUserRef} />
        <WarningModal
          getRef={r => {
            warningRef.current = r;
          }}
          onConfirm={() => navigate(-1)}
        />
      </>
    </CreateProductsStyle>
  );
};

export default CreateProducts;
