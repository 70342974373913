import React, { useCallback, useRef } from "react";
import ConvertationStyle from "./ConvertationStyle";
import { Button, Layout } from "../../components";
import { useTranslation } from "react-i18next";
import PaymentModal from "./PaymentModal";
import { AiOutlineArrowRight } from "react-icons/ai";

const Convertation = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const { t } = useTranslation();

  const paymentRef = useRef();

  const getPaymentRef = useCallback((ref) => {
    paymentRef.current = ref;
  }, []);

  return (
    <ConvertationStyle>
      <Layout>
        <div className='container'>
          <Button
            onClick={() => paymentRef.current?.open(1)}
            btnStyle={{ width: 200, marginRight: 20 }}
          >
            Dollar
            <span className='arrow'>
              <AiOutlineArrowRight />
            </span>
            Sum
          </Button>
          <Button
            onClick={() => paymentRef.current?.open(2)}
            btnStyle={{ width: 200 }}
          >
            Sum
            <span className='arrow'>
              <AiOutlineArrowRight />
            </span>
            Dollar
          </Button>
        </div>
      </Layout>

      <PaymentModal getRef={getPaymentRef} />
    </ConvertationStyle>
  );
};

export default Convertation;
