import styled from "styled-components";

const ButtonStyle = styled.div`
  .btn {
    padding: 10px 30px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 15px;
    border: none;
    width: 150px;
    height: 40px;
    background: rgb(2, 67, 185);
    background: linear-gradient(
      90deg,
      rgba(2, 67, 185, 1) 0%,
      rgba(49, 151, 227, 1) 100%
    );
  }

  .btn2 {
    padding: 5px 30px;
    border-radius: 5px;
    background: rgb(2, 67, 185);
    background: linear-gradient(
      90deg,
      rgba(2, 67, 185, 1) 0%,
      rgba(49, 151, 227, 1) 100%
    );
    color: #ffffff;
    font-size: 15px;
    border: none;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .loaderIconStyle {
      animation: animationLoginLoader infinite;
      animation-duration: 3s;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      .loader {
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    @keyframes animationLoginLoader {
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(360deg);
      }
    }
  }
`;
export default ButtonStyle;
