import React, { memo, useEffect, useState } from "react";
import PaymentModalStyle from "./PaymentModalStyle";
import Modal from "react-modal";
import {
  AiFillBank,
  AiFillCreditCard,
  AiFillDollarCircle,
} from "react-icons/ai";
import api from "../../../api";
import numberWithSpaces from "../../../helpers/numberWithSpaces";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const PaymentModal = ({ getRef, onConfirm = () => {}, onClose = () => {} }) => {
  const { t } = useTranslation();
  const { getMe } = useSelector((state) => state.main);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with '0' if needed
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [sum, setSum] = useState("");
  const [sum2, setSum2] = useState("");
  const [customer, setCustomer] = useState("");
  const [bonus, setBonus] = useState("");
  const [userCardCode, setUserCardCode] = useState("");
  const [monthS, setMonthS] = useState("");
  const [valuta, setValuta] = useState("USD");
  const [paym, setPaym] = useState("U_CashAccount");
  const [dollar, setDollar] = useState(1);
  const [dateC, setDateC] = useState(formattedDate);

  const [customerData, setCustomerData] = useState([]);
  const [typeOfPayment, setTypeOfPayment] = useState([
    {
      title: "Naqd",
      icon: <AiFillDollarCircle />,
      isActive: true,
      value: "U_CashAccount",
    },
    {
      title: "Karta",
      icon: <AiFillCreditCard />,
      isActive: false,
      value: "U_CardAccount",
    },
    {
      title: "Terminal",
      icon: <AiFillBank />,
      isActive: false,
      value: "U_TransAccount",
    },
  ]);
  const [valuteDate, setValuteDate] = useState([
    {
      title: "DOLLAR",
      value: "USD",
      isActive: true,
    },
    {
      title: "SUM",
      value: "UZS",
      isActive: false,
    },
  ]);
  const monthDat = [
    {
      title: "Январь",
      value: "JANUARY",
    },
    {
      title: "Февраль",
      value: "FEBRUARY",
    },
    {
      title: "Март",
      value: "MARCH",
    },
    {
      title: "Апрель",
      value: "APRIL",
    },
    {
      title: "Май",
      value: "MAY",
    },
    {
      title: "Июнь",
      value: "JUNE",
    },
    {
      title: "Июль",
      value: "JULY",
    },
    {
      title: "Август",
      value: "AUGUST",
    },
    {
      title: "Сентябрь",
      value: "SEPTEMBER",
    },
    {
      title: "Октябрь",
      value: "OCTOBER",
    },
    {
      title: "Ноябрь",
      value: "NOVEMBER",
    },
    {
      title: "Декабрь",
      value: "DECEMBER",
    },
  ];

  useEffect(() => {
    const ref = {
      open: () => setIsOpenModal(true),
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
    getUSD(dateC);
  }, []);

  const sending = () => {
    if (paym === "U_CardAccount" || paym === "U_TransAccount") {
      onConfirm(sum, paym, "UZS", dollar, dateC, userCardCode, monthS, sum2);
    } else {
      onConfirm(sum, paym, valuta, dollar, dateC, userCardCode, monthS, sum2);
    }
    setIsOpenModal(false);

    setSum("");
    setPaym("U_CashAccount");
    setValuta("USD");
    setDollar(1);
    setDateC("");
    setUserCardCode("");
    setCustomer("");
    setBonus("");
  };

  const changeActive = (v, index) => {
    setPaym(v);
    let a = [...typeOfPayment];

    for (let i = 0; i < a.length; i++) {
      if (i === index) {
        a[i].isActive = true;
      } else {
        a[i].isActive = false;
      }
    }

    setTypeOfPayment(a);

    if (valuta === "UZS") {
      setSum((Number(bonus) / dollar).toFixed(3));
    } else {
      setSum(Number(bonus));
    }
    setSum2(Number(bonus));
  };

  const changeActive2 = (v, index) => {
    setValuta(v);
    let a = [...valuteDate];
    for (let i = 0; i < a.length; i++) {
      if (i === index) {
        a[i].isActive = true;
      } else {
        a[i].isActive = false;
      }
    }
    setValuteDate(a);

    if (v === "UZS") {
      setSum((Number(bonus) / dollar).toFixed(3));
    } else {
      setSum(Number(bonus));
    }
    setSum2(Number(bonus));
  };

  const getUSD = (courseDate) => {
    setDateC(courseDate);
    api
      .post(`SBOBobService_GetCurrencyRate`, {
        Currency: "UZS",
        Date: courseDate,
      })
      .then((res) => {
        const dollarP = JSON.parse(res.data);
        setDollar(dollarP);
        if (valuta === "UZS" || paym !== "U_CashAccount") {
          setSum((Number(bonus) / dollarP).toFixed(3));
        } else {
          setSum(Number(bonus));
        }
        setSum2(Number(bonus));
      })
      .catch((err) => {
        console.log("err getUSD", err);
      });
  };

  const searchUser = (a = "") => {
    setCustomer(a);

    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and (contains(CardName, '${a}') or contains(Phone1, '${a}'))`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setCustomerData(resData);
        if (a.includes(" , ")) {
          setUserCardCode(a.split(" , ")[1]);
        }
      })
      .catch((err) => {
        console.log("err searchUser:", err);
      });
  };

  const changeMonth = (v) => {
    setMonthS(v);
  };

  //debounce
  useEffect(() => {
    const delay = 1000;
    let timeoutId;

    if (customer) {
      timeoutId = setTimeout(() => {
        searchUser(customer);
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [customer]);

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    setCustomer(newSearchTerm);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel='Example Modal'
      ariaHideApp={false}
    >
      <PaymentModalStyle>
        <div className='card'>
          <h2>{t("Добавить платеж")}</h2>

          <div className='halfCard'>
            <div className='insideMiniCard2'>
              <input
                type='text'
                placeholder={t("Клиент")}
                list='client2'
                className='inputTop'
                value={customer}
                onChange={handleChange}
              />

              <datalist id='client2'>
                <option value=''></option>
                {customerData.map((v, i) => (
                  <option
                    key={i}
                    value={`${get(v, "CardName", "")} , ${get(
                      v,
                      "CardCode",
                      ""
                    )} , ${get(getMe, `GroupCodes.${v.GroupCode}`, "")}`}
                  />
                ))}
              </datalist>
            </div>

            <div className='insideMiniCard2'>
              <select
                name='mm'
                id='mm'
                className='inputTop2'
                placeholder='Data'
                onClick={(v) => changeMonth(v.target.value)}
              >
                {monthDat.map((v, i) => (
                  <option key={i} value={v.value}>
                    {v.title}
                  </option>
                ))}
              </select>
            </div>
            <div className='insideMiniCard'>
              <p>{t("Bonus")}</p>
              <input
                type='number'
                className='input'
                defaultValue={bonus}
                onChange={(v) => {
                  setBonus(v.target.value);
                  if (valuta === "UZS" || paym !== "U_CashAccount") {
                    setSum((Number(v.target.value) / dollar).toFixed(3));
                  } else {
                    setSum(Number(v.target.value));
                  }
                  setSum2(Number(v.target.value));
                }}
              />
            </div>
            <div className='insideMiniCard'>
              <p>
                {t("Курс")}: {dollar}
              </p>

              <input
                type='date'
                placeholder='Цена'
                className='input'
                defaultValue={dateC}
                onChange={(v) => getUSD(v.target.value)}
              />
            </div>

            <div className='insideMiniCard'>
              <p>
                {t("Оплата")}: {numberWithSpaces(sum)} {valuta}
              </p>
              <input
                type='number'
                placeholder={t("Цена")}
                className='inputDisabled'
                value={sum}
                disabled={true}
                // onChange={(v) => setSum(v.target.value)}
              />
            </div>
          </div>

          <h3>{t("Способ оплаты")}</h3>
          <div className='radioBtnCard'>
            {typeOfPayment.map((v, i) => {
              return (
                <button
                  key={i}
                  onClick={() => changeActive(v.value, i)}
                  className={v.isActive ? "ac" : "inac"}
                >
                  {v.icon}
                  <p className='btnTitle'> {v.title}</p>
                </button>
              );
            })}
          </div>

          <h3>{t("Valyuta")}</h3>
          <div className='radioBtnCard2'>
            {valuteDate.map((v, i) => {
              return (
                <button
                  key={i}
                  onClick={() => changeActive2(v.value, i)}
                  className={v.isActive ? "ac" : "inac"}
                >
                  {v.icon}
                  <p className='btnTitle'> {v.title}</p>
                </button>
              );
            })}
          </div>

          <div className='centerCard'>
            <button className='btnN' onClick={() => onClose()}>
              {t("Нет")}
            </button>
            <button
              className='btnY'
              onClick={() => sending()}
              disabled={
                Number(sum) > 0 &&
                typeOfPayment.length &&
                valuta.length > 0 &&
                userCardCode.length > 0
                  ? false
                  : true
              }
            >
              {t("Да")}
            </button>
          </div>
        </div>
      </PaymentModalStyle>
    </Modal>
  );
};

export default memo(PaymentModal);
