import React, { memo, useEffect, useState } from "react";
import SuccessModalStyle from "./SuccessModalStyle";
import SuccessImage from "../../../assets/images/success.png";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SuccessModal = ({ title = "Выполнено успешно", getRef }) => {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    const ref = {
      open: () => setIsOpenModal(true),
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel='Example Modal'
      ariaHideApp={false}
    >
      <SuccessModalStyle>
        <div className='card'>
          <img src={SuccessImage} alt='error image' className='img' />
          <h2> {title}!</h2>

          <button className='btnY' onClick={() => setIsOpenModal(false)}>
            {t("Понятно")}
          </button>
        </div>
      </SuccessModalStyle>
    </Modal>
  );
};

export default memo(SuccessModal);
