const colors = {
  btnColor: '#2A2A2A',
  mainColor: '#197521',
  mainColor2: '#548754',
  lightBlue: '#B1B9D7',
  gray: '#C3C3C3',
  blue1: 'rgba(18, 77, 192, 1)',
  blue2: 'rgba(44, 121, 207, 1)',
  blue3: 'rgba(128, 234, 255, 1)',
};

export default colors;
