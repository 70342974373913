import styled from "styled-components";
import colors from "../../../assets/style/colors";

const ErrorModalStyle = styled.div`
  .modal {
    background-color: #00000077;
    position: fixed;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card {
    width: 300px;
  }
  .input {
    border: 2px solid ${colors.gray};
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    color: ${colors.mainColor};
    width: 90%;
    height: 10px;
    margin-bottom: 20px;
  }
  .inputTitle {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
  }
  .between {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .maintitle {
    color: ${colors.blue2};
    font-size: 19px;
    font-weight: 600;
    margin-top: 0;
    text-align: center;
  }
  .arrow {
    font-size: 15px;
    margin: 0 10px;
  }
`;
export default ErrorModalStyle;
