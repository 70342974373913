import styled from "styled-components";
import colors from "../../assets/style/colors";
import BackImage from "../../assets/images/back.jpg";

const ViewItemStyle = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .centerCard {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .greenTitle {
    margin: 0;
    font-weight: bold;
    font-size: 19px;
    color: green;
  }

  .redTitle {
    margin: 0;
    font-weight: bold;
    font-size: 19px;
    color: red;
  }
  .betweenCard {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .userSearchCard {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      .createUserBtn {
        padding: 11px 10px;
        margin-left: 5px;
        border-radius: 5px;
        background-color: ${colors.mainColor};
        font-size: 15px;
        color: #ffffff;
        border: none;
        width: 50px;
      }
    }
    .inputDefault {
      border: 2px solid ${colors.blue2};
      font-size: 15px;
      padding: 10px;
      border-radius: 5px;
      color: ${colors.blue1};
      width: 90%;
      margin-bottom: 20px;
      background-color: #f5f9ff;
      font-weight: bold;
      height: 15px;
    }
    .table {
      border-collapse: collapse;
      margin-top: 20px;
      background-color: #ffffff;
      width: 100%;
      td,
      th {
        border: 1px solid ${colors.gray};
        padding: 5px;
        text-align: center;
      }
      .inputStartPrice {
        background-color: #ffffff;
        border: 1px solid #c4c4c4;
        outline: none;
        width: 50px;
      }
    }
    .center {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .bottomCard {
    display: flex;
    width: 100%;
    .cancelBtn {
      background-color: red;
      margin: 50px;
      padding: 100px;
    }
  }
  .fio {
    padding: 0;
    margin: 0;
    font-size: 14px;
    margin-bottom: 2px;
    min-width: 50px;
  }
  .serialMainCard {
    width: 200px;
    background-color: #e0edff;
    height: 130px;
    padding: 10px;
    overflow: auto;
    p {
      margin: 0;
    }
  }
  .serialNumberTitle {
    margin-top: 0;
    font-weight: bold;
  }

  .tableContainerMini {
    width: 100%;
    p {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
    }
    table {
      border-collapse: collapse;
      width: 100%;
      th,
      td {
        border: 1px solid gray;
        text-align: center;
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .between {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .betweenMini {
      width: 50%;
    }
    .inputDefault {
      border: 2px solid ${colors.blue2};
      font-size: 15px;
      padding: 10px;
      border-radius: 5px;
      color: ${colors.blue1};
      width: 80%;
      margin-bottom: 20px;
      background-color: #f5f9ff;
      font-weight: bold;
      height: 15px;
    }
  }
  .tableContainerMini2 {
    width: 100%;
  }
  .commetnTitle {
    color: ${colors.blue1};
    font-weight: bold;
  }
  .vitrina {
    color: ${colors.blue1};
    margin: 30px 0 0 80px;
  }
`;
export default ViewItemStyle;
